/* FINISHEDPRODCHART */
export const GET_DASHBOARDDATA = "GET_DASHBOARDDATA"
export const GET_DASHBOARDDATA_SUCCESS = "GET_DASHBOARDDATA_SUCCESS"
export const GET_DASHBOARDDATA_FAIL = "GET_DASHBOARDDATA_FAIL"

export const GET_CALENDER_SCHEDULE = "GET_CALENDER_SCHEDULE"
export const GET_CALENDER_SCHEDULE_SUCCESS = "GET_CALENDER_SCHEDULE_SUCCESS"
export const GET_CALENDER_SCHEDULE_FAIL = "GET_CALENDER_SCHEDULE_FAIL"

export const GET_YEARLY_CHART = "GET_YEARLY_CHART"
export const GET_YEARLY_CHART_SUCCESS = "GET_YEARLY_CHART_SUCCESS"
export const GET_YEARLY_CHART_FAIL = "GET_YEARLY_CHART_FAIL"
