import React, { useEffect, useState } from "react"
import { Row, Col, Spinner, Button, FormGroup } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
import PropTypes from "prop-types"
import Switch from "react-switch"
import Select from "react-select"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import "assets/scss/datatables.scss"
import MyPagination from "components/Common/MyPagination"
import { deleteGym, getDistricts, getGym, updateGym } from "store/Gym/actions"
import DeleteModal from "components/Common/DeleteModal"
import { BiInfoCircle } from "react-icons/bi"
import { AvField, AvForm } from "availity-reactstrap-validation"

const GymListTable = ({ isMinimal, userGymData }) => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [finalDateFormated, setFinalDateFormated] = useState({
    from: "",
    to: "",
  })
  const [districtSeclect, setDistrictSeclect] = useState()

  const { loading, gyms, gymLoad, userDetails, districts } = useSelector(
    state => ({
      loading: state.Fitcom.loading,
      gymLoad: state.Fitcom.gymLoad,
      gyms: state.Fitcom.gyms,
      userDetails: state.Fitcom.userDetails,
      districts: state.Fitcom.districts,
    })
  )

  const [deleteItem, setDeleteItem] = useState({ status: false, id: "" })

  const handleData = () => {
    if (userGymData) {
      return userGymData
    } else {
      return gyms?.results
    }
  }

  //pages
  const totalPages = Math.ceil(gyms?.count / 10)
  const pages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(
      getGym(page, 10, searchText, finalDateFormated, districtSeclect?.id)
    )
  }, [dispatch, page, searchText, finalDateFormated, districtSeclect?.id])

  const handleDelete = id => {
    setDeleteItem({
      status: true,
      id: id,
    })
  }
  const handleDeleteEvent = () => {
    dispatch(deleteGym(deleteItem?.id))
    setDeleteItem({ status: false, id: "" })
  }

  const columns = [
    {
      dataField: "gym_id",
      text: "Gym Id",
      sort: true,
    },
    {
      dataField: "gym_name",
      text: "Gym Name",
    },
    {
      dataField: "sms_balance",
      text: "SMS",
    },
    {
      dataField: "expiry_date",
      text: "Expiry Date",
    },
    {
      dataField: "first_name",
      text: "Gym Owner",
    },
    {
      dataField: "phone",
      text: "Phone",
    },
    // {
    //   dataField: "is_active",
    //   text: "Status",
    // },
    {
      dataField: "action",
      text: "Action",
    },
  ]
  const singleViewColumns = columns?.filter(i => i.dataField !== "first_name")

  const roomData = map(handleData(), (item, index) => ({
    ...item,
    key: index,
    // is_active: (
    //   <Switch
    //     uncheckedIcon={<Offsymbol text={"Close"} />}
    //     checkedIcon={<OnSymbol text={"Open"} />}
    //     className="mb-sm-8 mb-2"
    //     onChange={e => dispatch(updateGym({ is_active: e }, item?.id, ""))}
    //     checked={item?.is_active}
    //     height={20}
    //     width={55}
    //     offColor={"#a2a2a2"}
    //     onColor={"#34c38f"}
    //   />
    // ),
    action: (
      <div>
        <Switch
          uncheckedIcon={<Offsymbol text={"Close"} />}
          checkedIcon={<OnSymbol text={"Open"} />}
          className="mb-sm-8 mb-2"
          onChange={e => dispatch(updateGym({ is_active: e }, item?.id, ""))}
          checked={item?.is_active}
          height={20}
          width={55}
          offColor={"#a2a2a2"}
          onColor={"#34c38f"}
        />
        <Link to={`/gym/update/${item.id}`} className="text-info mx-4 btn-sm">
          <i className="fa fa-pen"></i>
        </Link>

        <Link
          onClick={() => handleDelete(item?.id)}
          to={`#`}
          className="text-danger mx-1 btn-sm"
        >
          <i className="fas fa-trash"></i>
        </Link>
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }
  // /shop/make

  console.log(finalDateFormated)

  //District dropdown
  const [districtSearch, setDistrictSearch] = useState()

  const debounceDistrictSearch = debounce(
    value => setDistrictSearch(value),
    600
  )
  const handleRommTypeEnters = textEntered => {
    debounceDistrictSearch(textEntered)
  }

  function handlerDistrictFinalValue(event) {
    const district = {
      ...districtSeclect,
      title: event.label,
      id: event.value,
    }
    setDistrictSeclect(district)
  }

  const districtOptions =
    districts?.results &&
    districts?.results?.map((result, index) => ({
      key: index,
      label: result.district_name,
      value: result?.id,
    }))

  useEffect(() => {
    dispatch(getDistricts("", "", districtSearch))
  }, [districtSearch])
  //end of district dropdown

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteItem.status}
        onCloseClick={() => setDeleteItem({ status: false, id: "" })}
        onDeleteClick={handleDeleteEvent}
        loading={gymLoad}
      />
      <ToolkitProvider
        keyField="id"
        columns={isMinimal ? singleViewColumns : columns}
        data={roomData}
        search
      >
        {toolkitProps => (
          <React.Fragment>
            <Row className="">
              <Col className="col-12">
                <div className="search-box w-50 me-2 h-100 d-inline-block">
                  <div className="position-relative h-100 w-100 d-flex align-items-center">
                    <form
                      className="app-search d-lg-block w-100"
                      onChange={e => handleSearch(e)}
                    >
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          defaultValue={searchText}
                        />
                        <span className="bx bx-search-alt" />
                      </div>
                    </form>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col className="d-flex align-items-center justify-content-between col-12">
                <div className="me-4 d-flex align-items-center flex-wrap justify-content-between my-1">
                  <AvForm style={{ width: "fit-content" }}>
                    <div className="d-flex date_wrapper">
                      <label className="d-flex align-items-center p-0 me-2 mb-0">
                        Expiry date
                      </label>
                      <div className="d-flex align-items-center position-relative custom_time_input">
                        {/* <i className="bx bxs-timer font-size-22"></i> */}
                        <span className="ms-2">From</span>
                        <AvField
                          name="dateRange"
                          type="date"
                          validate={{
                            dateRange: {
                              start: { value: -5, units: "years" },
                              end: { value: 5, units: "years" },
                            },
                          }}
                          onChange={e =>
                            setFinalDateFormated({
                              ...finalDateFormated,
                              from: e.target.value,
                            })
                          }
                          value={finalDateFormated?.from}
                        />
                        <button
                          onClick={() =>
                            setFinalDateFormated({
                              ...finalDateFormated,
                              from: "",
                            })
                          }
                          type="button"
                          className="btn btn-white editable-cancel date_close btn-sm"
                        >
                          <i className="mdi mdi-close text-danger "></i>
                        </button>
                      </div>
                      <div
                        className="d-flex align-items-center position-relative custom_time_input mx-2"
                        style={{
                          width: "187px",
                        }}
                      >
                        {/* <i className="bx bxs-timer font-size-22"></i> */}
                        <span className="ms-2">to</span>

                        <AvField
                          name="dateRange"
                          className="mx-1"
                          type="date"
                          validate={{
                            dateRange: {
                              start: { value: -5, units: "years" },
                              end: { value: 5, units: "years" },
                            },
                          }}
                          onChange={e =>
                            setFinalDateFormated({
                              ...finalDateFormated,
                              to: e.target.value,
                            })
                          }
                          value={finalDateFormated?.to}
                        />
                        <button
                          onClick={() =>
                            setFinalDateFormated({
                              ...finalDateFormated,
                              to: "",
                            })
                          }
                          type="button"
                          className="btn btn-white editable-cancel date_close btn-sm"
                        >
                          <i className="mdi mdi-close text-danger "></i>
                        </button>
                      </div>
                    </div>
                  </AvForm>
                </div>
                <div style={{ width: "250px", height: "35px" }}>
                  <FormGroup className="d-flex align-items-center justify-content-center">
                    <label
                      className="m-0 me-2"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      District :
                    </label>
                    <div className="ajax-select mt-lg-0 select2-container w-100">
                      <Select
                        onInputChange={handleRommTypeEnters}
                        value={districtSeclect?.title}
                        placeholder={districtSeclect?.title}
                        onChange={handlerDistrictFinalValue}
                        options={districtOptions}
                        classNamePrefix="select2-selection"
                        isLoading={loading}
                      />
                    </div>
                  </FormGroup>
                </div>
                <Link to="/gym/create">
                  <Button type="submit" color="success" className="w-md">
                    Add New Fitness center
                  </Button>
                </Link>
              </Col>
            </Row>

            {handleData()?.length >= 1 ? (
              <>
                {loading ? (
                  <Spinner color="secondary" className="d-block m-auto" />
                ) : (
                  <>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField={"id"}
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            selectRow={selectRow}
                            classes={
                              "table align-middle table-nowrap floting-action"
                            }
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                          />
                        </div>
                      </Col>
                    </Row>
                    {!isMinimal && (
                      <MyPagination
                        totalPages={pages}
                        page={page}
                        setPage={setPage}
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <p className="text-info d-flex align-items-center ">
                <BiInfoCircle className="me-1" />
                {userDetails?.first_name} does not have a fitness center
              </p>
            )}
          </React.Fragment>
        )}
      </ToolkitProvider>
    </React.Fragment>
  )
}

export default GymListTable

GymListTable.propTypes = {
  isMinimal: PropTypes.bool,
  userGymData: PropTypes.array,
}

export const Offsymbol = ({ text }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 10,
        color: "#fff",
        padding: 2,
        marginRight: 10,
      }}
    >
      {" "}
      {text ? text : "No"}
    </div>
  )
}
Offsymbol.propTypes = {
  text: PropTypes.string,
}
export const OnSymbol = ({ text }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 10,
        color: "#fff",
        padding: 2,
        marginLeft: 10,
      }}
    >
      {" "}
      {text ? text : "Yes"}
    </div>
  )
}

OnSymbol.propTypes = {
  text: PropTypes.string,
}
