import React from "react"
import { Button, Col, FormGroup, Modal, ModalBody, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import { createState, updateState } from "store/Gym/actions"
import PropTypes from "prop-types"
import Spinner from "components/Common/Spinner"
import { IoCloseOutline } from "react-icons/io5"
import { isEmpty } from "lodash"

function StatesModal({ onCloseClick, show, data }) {
  const dispatch = useDispatch()

  const { loading } = useSelector(state => ({
    loading: state.Fitcom.stateLoad,
  }))

  const handleSubmit = (e, value) => {
    if (isEmpty(data)) {
      dispatch(createState(value, onCloseClick))
    } else {
      dispatch(updateState(value, data?.id, onCloseClick))
    }
  }

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="p-4">
        <p className="mb-0 mt-3">{isEmpty(data) ? "Create" : "Update"} State</p>
        <IoCloseOutline className="close-icon" onClick={onCloseClick} />

        <AvForm
          onValidSubmit={(e, input) => {
            handleSubmit(e, input)
          }}
        >
          <Row className="mt-2">
            <Col className={`col-12`}>
              <AvField
                name="state_name"
                label=""
                type="text"
                value={data?.state_name ?? ""}
                required
                placeholder="Enter state name"
              />
            </Col>

            <Col
              className={`d-flex align-items-start col-12 justify-content-end mt-3`}
            >
              <FormGroup className="mb-3">
                <label>&nbsp;</label>
                <div>
                  <Button outline type="submit" color="success">
                    {isEmpty(data) ? "Create" : "Update"} State
                    {loading && <Spinner className="mx-1" />}
                  </Button>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

export default StatesModal

StatesModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.bool,
  data: PropTypes.any,
}
