import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_ALL_GYM,
  GET_ALL_ACCOUNTS,
  CREATE_GYM,
  CREATE_ACCOUNT,
  UPDATE_ACCOUNT,
  DELETE_ACCOUNT,
  UPDATE_GYM,
  DELETE_GYM,
  CREATE_STATES,
  GET_STATES,
  DELETE_STATES,
  UPDATE_STATES,
  GET_DISTRICTS,
  CREATE_DISTRICT,
  UPDATE_DISTRICTS,
  DELETE_DISTRICTS,
  GET_SINGLE_ACCOUNTS,
  GET_SINGLE_GYM,
  GYM_SMS_RECHARGE,
  GET_RECHARGE_REQUEST,
  UPDATE_RECHARGE_REQUEST,
} from "./actionTypes"
import {
  createAccountSuccess,
  createAccountFail,
  getAccountSuccess,
  getAccountFail,
  getGymSuccess,
  getGymFail,
  createGymSuccess,
  createGymFail,
  deleteAccountSuccess,
  deleteAccountFail,
  updateAccountSuccess,
  updateAccountFail,
  updateGymSuccess,
  updateGymFail,
  deleteGymSuccess,
  deleteGymFail,
  getStatesSuccess,
  getStatesFail,
  createStateSuccess,
  createStateFail,
  updateStateSuccess,
  updateStateFail,
  deleteStateSuccess,
  deleteStateFail,
  getDistrictsSuccess,
  getDistrictsFail,
  createDistrictSuccess,
  createDistrictFail,
  updateDistrictSuccess,
  updateDistrictFail,
  deleteDistrictSuccess,
  deleteDistrictFail,
  getSingleAccountSuccess,
  getSingleAccountFail,
  getSingleGymSuccess,
  getSingleGymFail,
  gymSMSRechargeSuccess,
  gymSMSRechargeFail,
  getRechargeRequestSuccess,
  getRechargeRequestFail,
  updateRechargeRequestSuccess,
  updateRechargeRequestFail,
} from "./actions"

import { get, post, BASE_URL, del, ApiPut, patch } from "helpers/api_methods"
import { useErrorNotification } from "components/Common/Notification"
import { doneNotification, errorNotification } from "constants/notification"
import axios from "axios"

//urls
const accountUrl = "/account/"
const gymUrl = "/gym/gym-admin/"
const gymSMSUrl = "/gym/gym-sms"
const stateUrl = "/gym/state/"
const districtUrl = "/gym/district/"

//action
//ROOM TYPE
const createAccountApi = async ({ account }) => {
  console.log(account)
  const form_data = new FormData()
  {
    {
      account?.profile_pic &&
        form_data.append(
          "profile_pic",
          account?.profile_pic[0],
          account?.profile_pic[0]?.name
        )
    }
    form_data.append("first_name", account?.first_name),
      form_data.append("last_name", account?.last_name),
      form_data.append("phone", account?.phone),
      form_data.append("email", account?.email),
      form_data.append("address", account?.address)
  }
  try {
    let response = await axios({
      method: "post",
      url: `${BASE_URL}${accountUrl}`,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "token " + localStorage.getItem("token"),
      },
      data: form_data,
    })
    return response.data
  } catch (err) {
    return err?.response?.data
  }
}
function* createAccount({ payload }) {
  try {
    const response = yield call(createAccountApi, payload)
    if (response?.error_message) {
      errorNotification(response?.error_message)
      yield put(createAccountFail(response?.error_message))
    } else if (response?.pk) {
      yield put(createAccountSuccess(response))
      doneNotification("successfully registered new user")
      payload.history.push("/accounts")
    }
  } catch (error) {
    yield put(createAccountFail(error))
    useErrorNotification()
  }
}

const fetchAccountsApi = ({ page, searchText }) => {
  if (searchText) {
    return get(`${accountUrl}?search=${searchText && searchText}`)
  } else {
    return get(`${accountUrl}?page=${page ? page : 1}`)
  }
}
function* fetchAccounts({ payload }) {
  try {
    const response = yield call(fetchAccountsApi, payload)
    yield put(getAccountSuccess(response))
  } catch (error) {
    yield put(getAccountFail(error))
    useErrorNotification()
  }
}

const fetchAccountDetailsApi = userId => {
  return get(`${accountUrl}${userId}/`)
}
function* fetchAccountDetails({ payload }) {
  try {
    const response = yield call(fetchAccountDetailsApi, payload)
    yield put(getSingleAccountSuccess(response))
  } catch (error) {
    yield put(getSingleAccountFail(error))
    useErrorNotification()
  }
}

const deleteAccountApi = accountId => {
  return del(`${accountUrl}${accountId}/`)
}
function* deleteAccount({ payload }) {
  try {
    const response = yield call(deleteAccountApi, payload)
    yield put(deleteAccountSuccess(payload))
    doneNotification("Account Deleted Successfully!")
  } catch (error) {
    yield put(deleteAccountFail(error))
    useErrorNotification()
  }
}

const updateAccountApi = async ({ account, accountId }) => {
  console.log(account)
  const form_data = new FormData()
  {
    {
      account?.profile_pic &&
        form_data.append(
          "profile_pic",
          account?.profile_pic[0],
          account?.profile_pic[0]?.name
        )
    }
    form_data.append("first_name", account?.first_name),
      form_data.append("last_name", account?.last_name),
      form_data.append("phone", account?.phone),
      form_data.append("email", account?.email),
      form_data.append("address", account?.address)
  }
  try {
    let response = await axios({
      method: "patch",
      url: `${BASE_URL}${accountUrl}${accountId}/`,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "token " + localStorage.getItem("token"),
      },
      data: form_data,
    })
    return response.data
  } catch (err) {
    return err?.response?.data
  }
}

function* updateAccount({ payload }) {
  try {
    const response = yield call(updateAccountApi, payload)
    if (response?.pk) {
      yield put(updateAccountSuccess(response))
      doneNotification("Account Updated Successfully!")
      payload.history.goBack()
      // payload.history.push("/accounts")
    }
  } catch (error) {
    yield put(updateAccountFail(error))
    useErrorNotification()
  }
}

//gym
const fetchGymApi = ({ page, searchText, date, districtid }) => {
  console.log("title =>")
  console.log("data :", districtid)

  if (districtid) {
    return get(`${gymUrl}?disrict=${districtid && districtid}`)
  } else if (date?.from && date?.to) {
    return get(`${gymUrl}?expiry-start=${date?.from}&expiry-end=${date?.to}`)
  } else if (searchText) {
    return get(`${gymUrl}?search=${searchText && searchText}`)
  } else {
    return get(`${gymUrl}?page=${page ? page : 1}`)
  }
}
function* fetchGym({ payload }) {
  try {
    const response = yield call(fetchGymApi, payload)
    yield put(getGymSuccess(response))
  } catch (error) {
    yield put(getGymFail(error))
    useErrorNotification()
  }
}

const fetchGymDetailsApi = gymId => {
  return get(`${gymUrl}${gymId}/`)
}
function* fetchGymDetails({ payload }) {
  try {
    const response = yield call(fetchGymDetailsApi, payload)
    yield put(getSingleGymSuccess(response))
  } catch (error) {
    yield put(getSingleGymFail(error))
    useErrorNotification()
  }
}

const createGymApi = ({ gym }) => {
  return post(gymUrl, gym)
}
function* createGym({ payload }) {
  try {
    const response = yield call(createGymApi, payload)
    if (response?.id) {
      yield put(createGymSuccess(response))
      doneNotification("Gym Created Successfully!")
      payload.history.push("/gym")
    }
  } catch (error) {
    yield put(createGymFail(error))
    useErrorNotification()
  }
}

const updateGymApi = ({ gym, gymId }) => {
  return patch(`${gymUrl}${gymId}/`, gym)
}
function* updateGym({ payload }) {
  try {
    const response = yield call(updateGymApi, payload)
    if (response) {
      yield put(updateGymSuccess({ ...response, id: payload.gymId }))
      doneNotification("Gym Updated Successfully!")
      if (payload.history) {
        payload.history.push("/gym")
      }
    }
  } catch (error) {
    yield put(updateGymFail(error))
    useErrorNotification()
  }
}

const deleteGymApi = gymId => {
  return del(`${gymUrl}${gymId}/`)
}
function* deleteGym({ payload }) {
  try {
    const response = yield call(deleteGymApi, payload)
    yield put(deleteGymSuccess(payload))
    doneNotification("Deleted Successfully!")
  } catch (error) {
    yield put(deleteGymFail(error))
    useErrorNotification()
  }
}

const gymSmsRechargeApi = ({ sms }) => {
  return post(`${gymSMSUrl}`, sms)
}
function* gymSmsRecharge({ payload }) {
  try {
    const response = yield call(gymSmsRechargeApi, payload)
    if (response) {
      yield put(gymSMSRechargeSuccess(response))
      doneNotification("SMS Recharged Successfully!")
      payload.onCloseClick()
    }
  } catch (error) {
    yield put(gymSMSRechargeFail(error))
    useErrorNotification()
  }
}

//states
const fetchStatesApi = ({ page, searchText }) => {
  if (searchText) {
    return get(`${stateUrl}?search=${searchText && searchText}`)
  } else {
    return get(`${stateUrl}?page=${page ? page : 1}`)
  }
}
function* fetchStates({ payload }) {
  try {
    const response = yield call(fetchStatesApi, payload)
    yield put(getStatesSuccess(response))
  } catch (error) {
    yield put(getStatesFail(error))
    useErrorNotification()
  }
}

const createStateApi = ({ stateName }) => {
  return post(stateUrl, stateName)
}
function* createState({ payload }) {
  try {
    const response = yield call(createStateApi, payload)
    if (response?.id) {
      yield put(createStateSuccess(response))
      doneNotification("state created successfully!")
      payload.onCloseClick()
    }
  } catch (error) {
    yield put(createStateFail(error))
    useErrorNotification()
  }
}
const updateStateApi = ({ stateName, stateId }) => {
  return ApiPut(`${stateUrl}${stateId}/`, stateName)
}
function* updateState({ payload }) {
  try {
    const response = yield call(updateStateApi, payload)
    if (response?.id) {
      yield put(updateStateSuccess(response))
      doneNotification("state updated successfully!")
      payload.onCloseClick()
    }
  } catch (error) {
    yield put(updateStateFail(error))
    useErrorNotification()
  }
}
const deleteStateApi = stateId => {
  return del(`${stateUrl}${stateId}/`)
}
function* deleteState({ payload }) {
  try {
    const response = yield call(deleteStateApi, payload)
    yield put(deleteStateSuccess(payload))
    doneNotification("state deleted successfully!")
  } catch (error) {
    yield put(deleteStateFail(error))
    useErrorNotification()
  }
}

//district
const fetchDistrictApi = ({ page, searchText }) => {
  if (searchText) {
    return get(`${districtUrl}?search=${searchText && searchText}`)
  } else {
    return get(`${districtUrl}?page=${page ? page : 1}`)
  }
}
function* fetchDistrict({ payload }) {
  try {
    const response = yield call(fetchDistrictApi, payload)
    yield put(getDistrictsSuccess(response))
  } catch (error) {
    yield put(getDistrictsFail(error))
    useErrorNotification()
  }
}

const createDistrictApi = ({ districtName }) => {
  return post(districtUrl, districtName)
}
function* createDistrict({ payload }) {
  try {
    const response = yield call(createDistrictApi, payload)
    if (response?.id) {
      yield put(createDistrictSuccess(response))
      doneNotification("District created successfully!")
      payload.onCloseClick()
    }
  } catch (error) {
    yield put(createDistrictFail(error))
    useErrorNotification()
  }
}
const updateDistrictApi = ({ districtName, districtId }) => {
  return ApiPut(`${districtUrl}${districtId}/`, districtName)
}
function* updateDistrict({ payload }) {
  try {
    const response = yield call(updateDistrictApi, payload)
    if (response?.id) {
      yield put(updateDistrictSuccess(response))
      doneNotification("District updated successfully!")
      payload.onCloseClick()
    }
  } catch (error) {
    yield put(updateDistrictFail(error))
    useErrorNotification()
  }
}
const deleteDistrictApi = districtId => {
  return del(`${districtUrl}${districtId}/`)
}
function* deleteDistrict({ payload }) {
  try {
    const response = yield call(deleteDistrictApi, payload)
    yield put(deleteDistrictSuccess(payload))
    doneNotification("District deleted successfully!")
  } catch (error) {
    yield put(deleteDistrictFail(error))
    useErrorNotification()
  }
}

const getRechargeRequestApi = ({ page, limit }) => {
  return get(`/gym/sms-balance-request/?page=${page && page}&limit=${limit}`)
}
function* getRechargeRequest({ payload }) {
  try {
    const response = yield call(getRechargeRequestApi, payload)
    yield put(getRechargeRequestSuccess(response))
  } catch (error) {
    yield put(getRechargeRequestFail(error))
    useErrorNotification()
  }
}

const onUpdateRechargeRequestApi = ({ requestId, status }) => {
  return patch(`/gym/sms-balance-request/${requestId}/`, status)
}
function* onUpdateRechargeRequest({ payload }) {
  try {
    const response = yield call(onUpdateRechargeRequestApi, payload)
    if (response) {
      yield put(
        updateRechargeRequestSuccess({
          id: payload.requestId,
          status: payload.status,
        })
      )
    }
  } catch (error) {
    yield put(updateRechargeRequestFail(error))
    useErrorNotification()
  }
}

function* GymSaga() {
  yield takeEvery(GET_ALL_ACCOUNTS, fetchAccounts)
  yield takeEvery(GET_SINGLE_ACCOUNTS, fetchAccountDetails)
  yield takeEvery(CREATE_ACCOUNT, createAccount)
  yield takeEvery(UPDATE_ACCOUNT, updateAccount)
  yield takeEvery(DELETE_ACCOUNT, deleteAccount)

  yield takeEvery(GET_ALL_GYM, fetchGym)
  yield takeEvery(GET_SINGLE_GYM, fetchGymDetails)
  yield takeEvery(CREATE_GYM, createGym)
  yield takeEvery(UPDATE_GYM, updateGym)
  yield takeEvery(DELETE_GYM, deleteGym)
  yield takeEvery(GYM_SMS_RECHARGE, gymSmsRecharge)

  yield takeEvery(GET_STATES, fetchStates)
  yield takeEvery(CREATE_STATES, createState)
  yield takeEvery(UPDATE_STATES, updateState)
  yield takeEvery(DELETE_STATES, deleteState)

  yield takeEvery(GET_DISTRICTS, fetchDistrict)
  yield takeEvery(CREATE_DISTRICT, createDistrict)
  yield takeEvery(UPDATE_DISTRICTS, updateDistrict)
  yield takeEvery(DELETE_DISTRICTS, deleteDistrict)

  yield takeEvery(GET_RECHARGE_REQUEST, getRechargeRequest)
  yield takeEvery(UPDATE_RECHARGE_REQUEST, onUpdateRechargeRequest)
}

export default GymSaga
