import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"

//dashboard
import Dashboard from "./Dashboard/reducer"

// Rooms
import Fitcom from "./Gym/reducer"

const rootReducer = combineReducers({
  Layout,
  Dashboard,
  Login,
  Fitcom,
})

export default rootReducer
