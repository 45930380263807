import { AvForm } from "availity-reactstrap-validation"
import MetaTag from "components/Common/Meta-tag"
import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Container, Row } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import Spinner from "components/Common/Spinner"
import UserForm from "../From/Form"
import { getSingleAccount, updateAccount } from "store/actions"

function UpdateAccounts() {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()

  const { loading, userDetails } = useSelector(state => ({
    loading: state.Fitcom.userLoad,
    userDetails: state.Fitcom.userDetails,
  }))

  const [profilePic, setProfilePic] = useState({
    image: null,
  })

  const handleUserSubmit = (_, values) => {
    const finaldata = {
      ...values,
      profile_pic: profilePic.image,
      // isNewImage: profilePic.image !== null ? true : false,
    }
    dispatch(updateAccount(finaldata, params.id, history))
  }

  useEffect(() => {
    dispatch(getSingleAccount(params.id))
  }, [params.id])

  return (
    <div>
      <MetaTag title="Users" />
      <div className="page-content">
        <Breadcrumbs title="Users" breadcrumbItem="Update User" />
        <Container fluid>
          <Card className="col-8">
            <CardBody>
              <CardTitle className="mb-3">Update User</CardTitle>
              <AvForm
                onValidSubmit={(e, v) => {
                  handleUserSubmit(e, v)
                }}
              >
                <Row className="w-100">
                  <UserForm
                    setImage={setProfilePic}
                    updateValue={userDetails}
                  />
                  <button
                    type="submit"
                    className={`btn btn-success  w-sm mt-5`}
                    style={{
                      marginLeft: "auto",
                      width: "fit-content",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Update User {loading && <Spinner />}
                  </button>
                </Row>
              </AvForm>
            </CardBody>
          </Card>
        </Container>
      </div>
    </div>
  )
}

export default UpdateAccounts
