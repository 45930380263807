import React from "react"
import { Row, Col, Spinner } from "reactstrap"
import { useSelector } from "react-redux"
import { map } from "lodash"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import "assets/scss/datatables.scss"
import { BiInfoCircle } from "react-icons/bi"

const Transaction = () => {
  const { loading, gym_sms_transaction } = useSelector(state => ({
    loading: state.Fitcom.gymLoad,
    gym_sms_transaction: state.Fitcom.singleGym?.gym_sms_transaction,
  }))

  const columns = [
    {
      dataField: "auto_id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "gym_name",
      text: "Gym Name",
    },
    {
      dataField: "sms_amount",
      text: "SMS",
    },
    {
      dataField: "description",
      text: "Description",
    },
  ]

  const roomData = map(gym_sms_transaction, (item, index) => ({
    ...item,
    key: index,
  }))

  const defaultSorted = [
    {
      dataField: "auto_id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  // /shop/make
  return (
    <React.Fragment>
      <ToolkitProvider keyField="id" columns={columns} data={roomData} search>
        {toolkitProps => (
          <React.Fragment>
            {gym_sms_transaction?.length >= 1 ? (
              <>
                {loading ? (
                  <Spinner color="secondary" className="d-block m-auto" />
                ) : (
                  <>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField={"id"}
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            selectRow={selectRow}
                            classes={
                              "table align-middle table-nowrap floting-action"
                            }
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            ) : (
              <p className="text-info d-flex align-items-center ">
                <BiInfoCircle className="me-1" />
                No Transactions
              </p>
            )}
          </React.Fragment>
        )}
      </ToolkitProvider>
    </React.Fragment>
  )
}

export default Transaction
