import {
  GET_DASHBOARDDATA,
  GET_DASHBOARDDATA_SUCCESS,
  GET_DASHBOARDDATA_FAIL,
  GET_CALENDER_SCHEDULE,
  GET_CALENDER_SCHEDULE_SUCCESS,
  GET_CALENDER_SCHEDULE_FAIL,
  GET_YEARLY_CHART,
  GET_YEARLY_CHART_SUCCESS,
  GET_YEARLY_CHART_FAIL,
} from "./actionTypes"

export const getDashboardData = () => ({
  type: GET_DASHBOARDDATA,
})

export const getDashboardDataSuccess = dashboardData => ({
  type: GET_DASHBOARDDATA_SUCCESS,
  payload: dashboardData,
})
export const getDashboardDataFail = error => ({
  type: GET_DASHBOARDDATA_FAIL,
  payload: error,
})

export const getCalenderData = () => ({
  type: GET_CALENDER_SCHEDULE,
})

export const getCalenderDataSuccess = calenderData => ({
  type: GET_CALENDER_SCHEDULE_SUCCESS,
  payload: calenderData,
})
export const getCalenderDataFail = error => ({
  type: GET_CALENDER_SCHEDULE_FAIL,
  payload: error,
})

export const getYearlyChart = date => ({
  type: GET_YEARLY_CHART,
  payload: { date },
})

export const getYearlyChartSuccess = chartData => ({
  type: GET_YEARLY_CHART_SUCCESS,
  payload: chartData,
})
export const getYearlyChartFail = error => ({
  type: GET_YEARLY_CHART_FAIL,
  payload: error,
})
