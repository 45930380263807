import React from "react"
import { Redirect } from "react-router-dom"

import Login from "../pages/Authentication/Login"

// Dashboard
import Dashboard from "pages/Dashboard/index"
import Pages500 from "pages/404/pages-500"
import Pages404 from "pages/404/pages-404"

import FitnessCenters from "pages/FitnessCenters"
import CreateGym from "pages/FitnessCenters/Curd/CreateGym"
import Accounts from "pages/Accounts"
import CreateAccounts from "pages/Accounts/Curd/Create"
import States from "pages/States"
import District from "pages/District"
import UpdateAccounts from "pages/Accounts/Curd/Update"
import UserView from "pages/Accounts/SingleView"
import UpdateGym from "pages/FitnessCenters/Curd/UpdateGym"

const adminProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/gym", component: FitnessCenters },
  { path: "/gym/create", component: CreateGym },
  { path: "/gym/update/:id", component: UpdateGym },

  { path: "/accounts", component: Accounts },
  { path: "/accounts/create", component: CreateAccounts },
  { path: "/accounts/:id", component: UserView },
  { path: "/accounts/update/:id", component: UpdateAccounts },

  { path: "/states", component: States },
  { path: "/district", component: District },

  //this route should be at the end of all other routes eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/", component: Login },
  { path: "/404", component: Pages404 },
  { path: "/500", component: Pages500 },
]

export { publicRoutes, adminProtectedRoutes }
