import {
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_FAIL,
  CREATE_ACCOUNTS_SUCCESS,
  GET_ALL_ACCOUNTS,
  GET_ALL_ACCOUNTS_FAIL,
  GET_ALL_ACCOUNTS_SUCCESS,
  GET_ALL_GYM,
  GET_ALL_GYM_SUCCESS,
  GET_ALL_GYM_FAIL,
  CREATE_GYM,
  CREATE_GYM_SUCCESS,
  CREATE_GYM_FAIL,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_FAIL,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAIL,
  UPDATE_GYM,
  UPDATE_GYM_SUCCESS,
  UPDATE_GYM_FAIL,
  DELETE_GYM,
  DELETE_GYM_SUCCESS,
  DELETE_GYM_FAIL,
  CREATE_STATES,
  CREATE_STATES_SUCCESS,
  CREATE_STATES_FAIL,
  GET_STATES,
  GET_STATES_SUCCESS,
  GET_STATES_FAIL,
  UPDATE_STATES,
  UPDATE_STATES_SUCCESS,
  UPDATE_STATES_FAIL,
  DELETE_STATES,
  DELETE_STATES_SUCCESS,
  DELETE_STATES_FAIL,
  GET_DISTRICTS,
  GET_DISTRICTS_SUCCESS,
  GET_DISTRICTS_FAIL,
  CREATE_DISTRICT,
  CREATE_DISTRICT_SUCCESS,
  CREATE_DISTRICT_FAIL,
  UPDATE_DISTRICTS,
  UPDATE_DISTRICTS_SUCCESS,
  UPDATE_DISTRICTS_FAIL,
  DELETE_DISTRICTS,
  DELETE_DISTRICTS_SUCCESS,
  DELETE_DISTRICTS_FAIL,
  GET_SINGLE_ACCOUNTS,
  GET_SINGLE_ACCOUNTS_SUCCESS,
  GET_SINGLE_ACCOUNTS_FAIL,
  GET_SINGLE_GYM,
  GET_SINGLE_GYM_SUCCESS,
  GET_SINGLE_GYM_FAIL,
  GYM_SMS_RECHARGE,
  GYM_SMS_RECHARGE_SUCCESS,
  GYM_SMS_RECHARGE_FAIL,
  GET_RECHARGE_REQUEST,
  GET_RECHARGE_REQUEST_SUCCESS,
  GET_RECHARGE_REQUEST_FAIL,
  UPDATE_RECHARGE_REQUEST,
  UPDATE_RECHARGE_REQUEST_SUCCESS,
  UPDATE_RECHARGE_REQUEST_FAIL,
} from "./actionTypes"

// users
export const createAccount = (account, history) => ({
  type: CREATE_ACCOUNT,
  payload: { account, history },
})
export const createAccountSuccess = account => ({
  type: CREATE_ACCOUNTS_SUCCESS,
  payload: account,
})
export const createAccountFail = error => ({
  type: CREATE_ACCOUNT_FAIL,
  payload: error,
})
export const getAccount = (page, limit, searchText) => ({
  type: GET_ALL_ACCOUNTS,
  payload: { page, limit, searchText },
})
export const getAccountSuccess = users => ({
  type: GET_ALL_ACCOUNTS_SUCCESS,
  payload: users,
})
export const getAccountFail = error => ({
  type: GET_ALL_ACCOUNTS_FAIL,
  payload: error,
})
export const getSingleAccount = userId => ({
  type: GET_SINGLE_ACCOUNTS,
  payload: userId,
})
export const getSingleAccountSuccess = user => ({
  type: GET_SINGLE_ACCOUNTS_SUCCESS,
  payload: user,
})
export const getSingleAccountFail = error => ({
  type: GET_SINGLE_ACCOUNTS_FAIL,
  payload: error,
})

export const deleteAccount = accountId => ({
  type: DELETE_ACCOUNT,
  payload: accountId,
})
export const deleteAccountSuccess = accountId => ({
  type: DELETE_ACCOUNT_SUCCESS,
  payload: accountId,
})
export const deleteAccountFail = error => ({
  type: DELETE_ACCOUNT_FAIL,
  payload: error,
})

export const updateAccount = (account, accountId, history) => ({
  type: UPDATE_ACCOUNT,
  payload: { account, accountId, history },
})
export const updateAccountSuccess = account => ({
  type: UPDATE_ACCOUNT_SUCCESS,
  payload: account,
})
export const updateAccountFail = error => ({
  type: UPDATE_ACCOUNT_FAIL,
  payload: error,
})

//
//gym
export const getGym = (page, limit, searchText, date, districtid) => ({
  type: GET_ALL_GYM,
  payload: { page, limit, searchText, date, districtid },
})
export const getGymSuccess = gyms => ({
  type: GET_ALL_GYM_SUCCESS,
  payload: gyms,
})
export const getGymFail = error => ({
  type: GET_ALL_GYM_FAIL,
  payload: error,
})
export const getSingleGym = gymId => ({
  type: GET_SINGLE_GYM,
  payload: gymId,
})
export const getSingleGymSuccess = gym => ({
  type: GET_SINGLE_GYM_SUCCESS,
  payload: gym,
})
export const getSingleGymFail = error => ({
  type: GET_SINGLE_GYM_FAIL,
  payload: error,
})
export const createGym = (gym, history) => ({
  type: CREATE_GYM,
  payload: { gym, history },
})
export const createGymSuccess = gym => ({
  type: CREATE_GYM_SUCCESS,
  payload: gym,
})
export const createGymFail = error => ({
  type: CREATE_GYM_FAIL,
  payload: error,
})
export const updateGym = (gym, gymId, history) => ({
  type: UPDATE_GYM,
  payload: { gym, gymId, history },
})
export const updateGymSuccess = gym => ({
  type: UPDATE_GYM_SUCCESS,
  payload: gym,
})
export const updateGymFail = error => ({
  type: UPDATE_GYM_FAIL,
  payload: error,
})
export const deleteGym = gymId => ({
  type: DELETE_GYM,
  payload: gymId,
})
export const deleteGymSuccess = gym => ({
  type: DELETE_GYM_SUCCESS,
  payload: gym,
})
export const deleteGymFail = error => ({
  type: DELETE_GYM_FAIL,
  payload: error,
})

// sms
export const gymSMSRecharge = (sms, onCloseClick) => ({
  type: GYM_SMS_RECHARGE,
  payload: { sms, onCloseClick },
})
export const gymSMSRechargeSuccess = sms => ({
  type: GYM_SMS_RECHARGE_SUCCESS,
  payload: sms,
})
export const gymSMSRechargeFail = error => ({
  type: GYM_SMS_RECHARGE_FAIL,
  payload: error,
})

// STATE
export const getStates = (page, limit, searchText) => ({
  type: GET_STATES,
  payload: { page, limit, searchText },
})
export const getStatesSuccess = state => ({
  type: GET_STATES_SUCCESS,
  payload: state,
})
export const getStatesFail = error => ({
  type: GET_STATES_FAIL,
  payload: error,
})

export const createState = (stateName, onCloseClick) => ({
  type: CREATE_STATES,
  payload: { stateName, onCloseClick },
})
export const createStateSuccess = state => ({
  type: CREATE_STATES_SUCCESS,
  payload: state,
})
export const createStateFail = error => ({
  type: CREATE_STATES_FAIL,
  payload: error,
})

export const updateState = (stateName, stateId, onCloseClick) => ({
  type: UPDATE_STATES,
  payload: { stateName, stateId, onCloseClick },
})
export const updateStateSuccess = state => ({
  type: UPDATE_STATES_SUCCESS,
  payload: state,
})
export const updateStateFail = error => ({
  type: UPDATE_STATES_FAIL,
  payload: error,
})
export const deleteState = stateId => ({
  type: DELETE_STATES,
  payload: stateId,
})
export const deleteStateSuccess = stateId => ({
  type: DELETE_STATES_SUCCESS,
  payload: stateId,
})
export const deleteStateFail = error => ({
  type: DELETE_STATES_FAIL,
  payload: error,
})

// DISTRICTS
export const getDistricts = (page, limit, searchText) => ({
  type: GET_DISTRICTS,
  payload: { page, limit, searchText },
})
export const getDistrictsSuccess = districts => ({
  type: GET_DISTRICTS_SUCCESS,
  payload: districts,
})
export const getDistrictsFail = error => ({
  type: GET_DISTRICTS_FAIL,
  payload: error,
})

export const createDistrict = (districtName, onCloseClick) => ({
  type: CREATE_DISTRICT,
  payload: { districtName, onCloseClick },
})
export const createDistrictSuccess = district => ({
  type: CREATE_DISTRICT_SUCCESS,
  payload: district,
})
export const createDistrictFail = error => ({
  type: CREATE_DISTRICT_FAIL,
  payload: error,
})

export const updateDistrict = (districtName, districtId, onCloseClick) => ({
  type: UPDATE_DISTRICTS,
  payload: { districtName, districtId, onCloseClick },
})
export const updateDistrictSuccess = state => ({
  type: UPDATE_DISTRICTS_SUCCESS,
  payload: state,
})
export const updateDistrictFail = error => ({
  type: UPDATE_DISTRICTS_FAIL,
  payload: error,
})
export const deleteDistrict = districtId => ({
  type: DELETE_DISTRICTS,
  payload: districtId,
})
export const deleteDistrictSuccess = districtId => ({
  type: DELETE_DISTRICTS_SUCCESS,
  payload: districtId,
})
export const deleteDistrictFail = error => ({
  type: DELETE_DISTRICTS_FAIL,
  payload: error,
})

export const getRechargeRequest = (page, limit) => ({
  type: GET_RECHARGE_REQUEST,
  payload: { page, limit },
})
export const getRechargeRequestSuccess = recharge => ({
  type: GET_RECHARGE_REQUEST_SUCCESS,
  payload: recharge,
})
export const getRechargeRequestFail = error => ({
  type: GET_RECHARGE_REQUEST_FAIL,
  payload: error,
})

export const updateRechargeRequest = (requestId, status) => ({
  type: UPDATE_RECHARGE_REQUEST,
  payload: { requestId, status },
})
export const updateRechargeRequestSuccess = res => ({
  type: UPDATE_RECHARGE_REQUEST_SUCCESS,
  payload: res,
})
export const updateRechargeRequestFail = error => ({
  type: UPDATE_RECHARGE_REQUEST_FAIL,
  payload: error,
})
