import React from "react"
import { IoIosFitness } from "react-icons/io"
import { IoSettingsSharp } from "react-icons/io5"
import { MdSms, MdSupervisorAccount } from "react-icons/md"

export const adminSidebar = [
  {
    heading: "Dashboard",
    badgeClass: "",
    badgeValue: "",
    routeLink: "/dashboard",
    iconClass: "dashboard",
  },
  {
    heading: "All Fitness centers",
    badgeClass: "",
    badgeValue: "",
    routeLink: "/gym",
    icon: <IoIosFitness size={"1.1rem"} style={{ marginRight: 14 }} />,
  },
  {
    heading: "All Users",
    badgeClass: "",
    badgeValue: "",
    routeLink: "/accounts",
    iconClass: "user",
    icon: <MdSupervisorAccount size={"1.1rem"} style={{ marginRight: 14 }} />,
  },

  {
    heading: "Settings",
    badgeClass: "danger",
    badgeValue: "",
    icon: <IoSettingsSharp size={"1.1rem"} style={{ marginRight: 14 }} />,
    subTitles: [
      { title: "States", routeLink: "/states" },
      { title: "District", routeLink: "/district" },
    ],
  },
]
