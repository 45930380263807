import { AvField } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { Col, FormGroup, Row } from "reactstrap"
import ProTypes from "prop-types"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { debounce } from "lodash"
import { getAccount, getDistricts } from "store/Gym/actions"

function GymForm({
  updateValue,
  districtSeclect,
  setDistrictSeclect,
  accountSeclect,
  setAccountSeclect,
}) {
  const dispatch = useDispatch()

  const { loading, users, districts } = useSelector(state => ({
    loading: state.Fitcom.loading,
    users: state.Fitcom.users,
    districts: state.Fitcom.districts,
  }))

  //District dropdown
  const [districtSearch, setDistrictSearch] = useState()

  const debounceDistrictSearch = debounce(
    value => setDistrictSearch(value),
    600
  )
  const handleRommTypeEnters = textEntered => {
    debounceDistrictSearch(textEntered)
  }

  function handlerDistrictFinalValue(event) {
    const district = {
      ...districtSeclect,
      title: event.label,
      id: event.value,
    }
    setDistrictSeclect(district)
  }

  const districtOptions =
    districts?.results &&
    districts?.results?.map((result, index) => ({
      key: index,
      label: result.district_name,
      value: result?.id,
    }))

  useEffect(() => {
    dispatch(getDistricts("", "", districtSearch))
  }, [districtSearch])
  //end of district dropdown

  //account dropdown
  const [accountSearch, setAccountSearch] = useState()

  const debounceAccountSearch = debounce(value => setAccountSearch(value), 600)
  const handleAccountEnters = textEntered => {
    debounceAccountSearch(textEntered)
  }

  function handlerAccountFinalValue(event) {
    const account = {
      ...accountSeclect,
      title: event.label,
      id: event.value,
    }
    setAccountSeclect(account)
  }

  const accountOptions =
    users?.results &&
    users?.results?.map((result, index) => ({
      key: index,
      label: result.first_name,
      value: result?.id,
    }))

  useEffect(() => {
    dispatch(getAccount("", "", accountSearch))
  }, [accountSearch])
  //end of account dropdown

  return (
    <div>
      <FormGroup className="mb-3">
        <label>Account :</label>
        <div className="mb-3 ajax-select mt-lg-0 select2-container">
          <Select
            onInputChange={handleAccountEnters}
            value={accountSeclect?.title}
            placeholder={accountSeclect?.title}
            onChange={handlerAccountFinalValue}
            options={accountOptions}
            classNamePrefix="select2-selection"
            isLoading={loading}
          />
        </div>
      </FormGroup>

      <FormGroup className="mb-3">
        <label>District :</label>
        <div className="mb-3 ajax-select mt-lg-0 select2-container">
          <Select
            onInputChange={handleRommTypeEnters}
            value={districtSeclect?.title}
            placeholder={districtSeclect?.title}
            onChange={handlerDistrictFinalValue}
            options={districtOptions}
            classNamePrefix="select2-selection"
            isLoading={loading}
          />
        </div>
      </FormGroup>

      <Row className="py-2">
        <Col md={6}>
          <AvField
            label="Gym Name"
            value={updateValue ? updateValue?.gym_name : ""}
            name="gym_name"
            type="text"
            required
          />
        </Col>
        <Col md={6} className="sm:mt-2">
          <AvField
            style={{ height: "37px" }}
            label="Subscription Amount"
            name="subscription_amount"
            value={updateValue ? updateValue?.subscription_amount : ""}
            type="number"
          />
        </Col>
        <Col md={6} className="mt-2">
          <AvField
            label="Expiry Date"
            name="expiry_date"
            value={updateValue ? updateValue?.expiry_date : null}
            type="date"
          />
        </Col>
      </Row>
    </div>
  )
}

export default GymForm

GymForm.propTypes = {
  updateValue: ProTypes.object,
  districtSeclect: ProTypes.object,
  setDistrictSeclect: ProTypes.func,
  accountSeclect: ProTypes.object,
  setAccountSeclect: ProTypes.func,
}
