//gym
export const GET_ALL_GYM = "GET_ALL_GYM"
export const GET_ALL_GYM_SUCCESS = "GET_ALL_GYM_SUCCESS"
export const GET_ALL_GYM_FAIL = "GET_ALL_GYM_FAIL"

export const CREATE_GYM = "CREATE_GYM"
export const CREATE_GYM_SUCCESS = "CREATE_GYM_SUCCESS"
export const CREATE_GYM_FAIL = "CREATE_GYM_FAIL"

export const GET_SINGLE_GYM = "GET_SINGLE_GYM"
export const GET_SINGLE_GYM_SUCCESS = "GET_SINGLE_GYM_SUCCESS"
export const GET_SINGLE_GYM_FAIL = "GET_SINGLE_GYM_FAIL"

export const UPDATE_GYM = "UPDATE_GYM"
export const UPDATE_GYM_SUCCESS = "UPDATE_GYM_SUCCESS"
export const UPDATE_GYM_FAIL = "UPDATE_GYM_FAIL"

export const DELETE_GYM = "DELETE_GYM"
export const DELETE_GYM_SUCCESS = "DELETE_GYM_SUCCESS"
export const DELETE_GYM_FAIL = "DELETE_GYM_FAIL"

export const GYM_SMS_RECHARGE = "GYM_SMS_RECHARGE"
export const GYM_SMS_RECHARGE_SUCCESS = "GYM_SMS_RECHARGE_SUCCESS"
export const GYM_SMS_RECHARGE_FAIL = "GYM_SMS_RECHARGE_FAIL"

/* room type */
export const GET_ALL_ACCOUNTS = "GET_ALL_ACCOUNTS"
export const GET_ALL_ACCOUNTS_SUCCESS = "GET_ALL_ACCOUNTS_SUCCESS"
export const GET_ALL_ACCOUNTS_FAIL = "GET_ALL_ACCOUNTS_FAIL"

export const GET_SINGLE_ACCOUNTS = "GET_SINGLE_ACCOUNTS"
export const GET_SINGLE_ACCOUNTS_SUCCESS = "GET_SINGLE_ACCOUNTS_SUCCESS"
export const GET_SINGLE_ACCOUNTS_FAIL = "GET_SINGLE_ACCOUNTS_FAIL"

export const CREATE_ACCOUNT = "CREATE_ACCOUNT"
export const CREATE_ACCOUNTS_SUCCESS = "CREATE_ACCOUNTS_SUCCESS"
export const CREATE_ACCOUNT_FAIL = "CREATE_ACCOUNT_FAIL"

export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT"
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS"
export const UPDATE_ACCOUNT_FAIL = "UPDATE_ACCOUNT_FAIL"

export const DELETE_ACCOUNT = "DELETE_ACCOUNT"
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS"
export const DELETE_ACCOUNT_FAIL = "DELETE_ACCOUNT_FAIL"

//state
export const GET_STATES = "GET_STATES"
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS"
export const GET_STATES_FAIL = "GET_STATES_FAIL"

export const CREATE_STATES = "CREATE_STATES"
export const CREATE_STATES_SUCCESS = "CREATE_STATES_SUCCESS"
export const CREATE_STATES_FAIL = "CREATE_STATES_FAIL"

export const UPDATE_STATES = "UPDATE_STATES"
export const UPDATE_STATES_SUCCESS = "UPDATE_STATES_SUCCESS"
export const UPDATE_STATES_FAIL = "UPDATE_STATES_FAIL"

export const DELETE_STATES = "DELETE_STATES"
export const DELETE_STATES_SUCCESS = "DELETE_STATES_SUCCESS"
export const DELETE_STATES_FAIL = "DELETE_STATES_FAIL"

//district
export const GET_DISTRICTS = "GET_DISTRICTS"
export const GET_DISTRICTS_SUCCESS = "GET_DISTRICTS_SUCCESS"
export const GET_DISTRICTS_FAIL = "GET_DISTRICTS_FAIL"

export const CREATE_DISTRICT = "CREATE_DISTRICT"
export const CREATE_DISTRICT_SUCCESS = "CREATE_DISTRICT_SUCCESS"
export const CREATE_DISTRICT_FAIL = "CREATE_DISTRICT_FAIL"

export const UPDATE_DISTRICTS = "UPDATE_DISTRICTS"
export const UPDATE_DISTRICTS_SUCCESS = "UPDATE_DISTRICTS_SUCCESS"
export const UPDATE_DISTRICTS_FAIL = "UPDATE_DISTRICTS_FAIL"

export const DELETE_DISTRICTS = "DELETE_DISTRICTS"
export const DELETE_DISTRICTS_SUCCESS = "DELETE_DISTRICTS_SUCCESS"
export const DELETE_DISTRICTS_FAIL = "DELETE_DISTRICTS_FAIL"

export const GET_RECHARGE_REQUEST = "GET_RECHARGE_REQUEST"
export const GET_RECHARGE_REQUEST_SUCCESS = "GET_RECHARGE_REQUEST_SUCCESS"
export const GET_RECHARGE_REQUEST_FAIL = "GET_RECHARGE_REQUEST_FAIL"

export const UPDATE_RECHARGE_REQUEST = "UPDATE_RECHARGE_REQUEST"
export const UPDATE_RECHARGE_REQUEST_SUCCESS = "UPDATE_RECHARGE_REQUEST_SUCCESS"
export const UPDATE_RECHARGE_REQUEST_FAIL = "UPDATE_RECHARGE_REQUEST_FAIL"
