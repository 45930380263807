import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import PropTypes from "prop-types"
import { IMAGE_URL } from "helpers/api_methods"

function ImageCreate({ setImage, image }) {
  const [selectedFiles, setselectedFiles] = useState([])

  function handleAcceptedFiles(files) {
    setImage({
      image: files,
    })

    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  useEffect(() => {
    if (image !== null && image) {
      setselectedFiles([
        { name: "profile pic", preview: `${IMAGE_URL}${image}` },
      ])
    }
  }, [image])

  console.log("title =>", image)
  console.log("data :", selectedFiles[0]?.preview)
  return (
    <div className="d-flex align-items-start pt-2">
      {selectedFiles[0]?.preview && (
        <div className="">
          {selectedFiles.map((f, i) => (
            <img
              key={i}
              className="dotted mx-2 rounded"
              style={{ height: "100px", width: "100px" }}
              alt={f.name}
              src={f.preview}
            />
          ))}
        </div>
      )}
      <Dropzone
        onDrop={acceptedFiles => {
          handleAcceptedFiles(acceptedFiles)
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="">
            <div className="needsclick" {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="mb-3 m-0 file-box bg-success rounded image-i-size">
                <i className="bx bx-image-add image-i-color bx-burst-hover" />
              </div>
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  )
}

export default ImageCreate

ImageCreate.propTypes = {
  setImage: PropTypes.any,
  image: PropTypes.any,
}
