import React, { useState } from "react"
import SwiperCore, { Navigation, Thumbs, Autoplay } from "swiper"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"
import { Swiper, SwiperSlide } from "swiper/react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import SMSModal from "pages/FitnessCenters/Eelements/SMSModal"
import { avatar } from "assets/images/Defualt"

SwiperCore.use([Navigation, Thumbs])
SwiperCore.use([Autoplay])

const ThumbSlider = ({ data, isUser }) => {
  const oddData = data?.length >= 1 && data?.filter((_, key) => key % 2 === 0)
  const evenData = data?.length >= 1 && data?.filter((_, key) => key % 2 !== 0)

  const [selectedSms, setSelectedSms] = useState({
    status: false,
    id: "",
    data: {},
  })

  const handleRechargeSms = item => {
    setSelectedSms({
      status: true,
      id: item.id,
      data: item,
    })
  }
  return (
    <>
      <SMSModal
        show={selectedSms.status}
        onCloseClick={() => setSelectedSms({ status: false, id: "" })}
        gym={selectedSms?.data}
      />
      <div className="h-100">
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          spaceBetween={10}
          navigation={false}
          className="mySwiper2 h-75"
          autoplay={true}
        >
          {oddData?.length >= 1 &&
            oddData?.map((item, key) => (
              <SwiperSlide key={key} className="h-100">
                {ThumbItem(item)}
              </SwiperSlide>
            ))}
        </Swiper>

        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          spaceBetween={10}
          navigation={false}
          className="mySwiper2 h-75"
          autoplay={true}
        >
          {evenData?.length >= 1 &&
            evenData?.map((item, key) => (
              <SwiperSlide key={key} className="h-100">
                {ThumbItem(item)}
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </>
  )

  function ThumbItem(item) {
    const history = useHistory()
    return (
      <div className="h-100">
        <div className="bg-light p-3 d-flex mb-3 rounded">
          {isUser && (
            <img
              src={item?.profile_pic ? item?.profile_pic : avatar}
              alt="profile_pic"
              className="avatar-sm rounded me-3"
            />
          )}
          <div className="flex-grow-1">
            <h5
              className="font-size-15 mb-2"
              onClick={() =>
                history.push(
                  isUser ? `/accounts/${item?.id}` : `/gym/update/${item.id}`
                )
              }
              style={{ cursor: "pointer" }}
            >
              <a href="candidate-overview" className="text-body">
                {isUser
                  ? `${item?.first_name} ${item?.last_name}`
                  : item?.gym_name}
              </a>{" "}
              {!isUser && (
                <span className="badge badge-soft-warning">
                  {item?.sms_balance} SMS
                </span>
              )}
            </h5>
            <p className="mb-0 text-muted">
              {item?.phone ? (
                <>
                  {" "}
                  <i className="bx bx-phone text-body align-middle"></i>&nbsp;
                  {item?.phone},&nbsp;
                </>
              ) : (
                ""
              )}
              {item?.email ? (
                <span>
                  <i className="bx bx-mail-send text-body align-middle"></i>{" "}
                  {item?.email}
                </span>
              ) : (
                ""
              )}
            </p>
            {!isUser && (
              <span
                className="btn btn-sm btn-outline-success custom-sms-btn py-1 px-1"
                onClick={() => handleRechargeSms(item)}
              >
                Recharge SMS
              </span>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default ThumbSlider

ThumbSlider.propTypes = {
  data: PropTypes.any,
  isUser: PropTypes.bool,
}
