import {
  GET_DASHBOARDDATA,
  GET_DASHBOARDDATA_SUCCESS,
  GET_DASHBOARDDATA_FAIL,
  GET_CALENDER_SCHEDULE,
  GET_CALENDER_SCHEDULE_SUCCESS,
  GET_CALENDER_SCHEDULE_FAIL,
  GET_YEARLY_CHART_SUCCESS,
  GET_YEARLY_CHART_FAIL,
  GET_YEARLY_CHART,
} from "./actionTypes"

const INIT_STATE = {
  error: {},
  loading: false,
  dashboardData: {},
  calenderData: [],
  yearlyGymData: [],
  yearGymLoading: false,
}

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DASHBOARDDATA:
      return {
        ...state,
        loading: true,
      }
    case GET_YEARLY_CHART:
      return {
        ...state,
        yearGymLoading: true,
      }
    case GET_DASHBOARDDATA_SUCCESS:
      return {
        ...state,
        dashboardData: action.payload,
        loading: false,
        error: {},
      }

    case GET_DASHBOARDDATA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_CALENDER_SCHEDULE:
      return {
        ...state,
        loading: true,
      }

    case GET_CALENDER_SCHEDULE_SUCCESS:
      return {
        ...state,
        calenderData: action.payload,
        loading: false,
        error: {},
      }

    case GET_CALENDER_SCHEDULE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_YEARLY_CHART_SUCCESS:
      return {
        ...state,
        yearlyGymData: action.payload,
        yearGymLoading: false,
      }

    case GET_YEARLY_CHART_FAIL:
      return {
        ...state,
        error: action.payload,
        yearGymLoading: false,
      }

    default:
      return state
  }
}

export default Dashboard
