import MetaTag from "components/Common/Meta-tag"
import { Button, Container } from "reactstrap"
import React, { useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { AvForm } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import { createGym } from "store/Gym/actions"
import GymForm from "../From/Form"
import { useHistory } from "react-router-dom"
import Spinner from "components/Common/Spinner"

function CreateGym() {
  const dispatch = useDispatch()
  const history = useHistory()

  const { loading } = useSelector(state => ({
    loading: state.Fitcom.gymLoad,
  }))

  const [districtSeclect, setDistrictSeclect] = useState({
    id: "",
    title: "Search District",
  })
  const [accountSeclect, setAccountSeclect] = useState({
    id: "",
    title: "Search Users",
  })
  const handleGymSubmit = (_, values) => {
    const finalData = {
      ...values,
      account: accountSeclect.id,
      district: districtSeclect.id,
    }
    dispatch(createGym(finalData, history))
  }
  return (
    <div>
      <MetaTag title="Fitness centers" />
      <div className="page-content">
        <Breadcrumbs title="Gym" breadcrumbItem="Fitness centers" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col md={8}>
                <Card>
                  <CardBody>
                    <AvForm
                      onValidSubmit={(e, v) => {
                        handleGymSubmit(e, v)
                      }}
                    >
                      <GymForm
                        districtSeclect={districtSeclect}
                        setDistrictSeclect={setDistrictSeclect}
                        accountSeclect={accountSeclect}
                        setAccountSeclect={setAccountSeclect}
                      />
                      <div className="d-flex align-items-center justify-content-end">
                        <Button color="success" className="px-4" type="submit">
                          Create Gym {loading && <Spinner />}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default CreateGym
