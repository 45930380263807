import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody } from "reactstrap"
import SMSCard from "./SMSCard"
import { useDispatch } from "react-redux"
import { getSingleGymSuccess } from "store/actions"

function SMSModal({ onCloseClick, show, gym }) {
  const dispatch = useDispatch()

  useEffect(() => {
    if (gym?.id) {
      dispatch(getSingleGymSuccess(gym))
    }
  }, [gym])

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={modalStyle}
      className="delete-modal"
    >
      <ModalBody style={modalBodyStyle} className="p-0 sms-modal">
        <SMSCard isModal={true} onCloseClick={onCloseClick} />
      </ModalBody>
    </Modal>
  )
}

export default SMSModal

SMSModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.bool,
  gym: PropTypes.any,
}

const modalStyle = {
  width: 300,
}
const modalBodyStyle = {
  minHeight: 300,
}
