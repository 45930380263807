import { useSuccessNotification } from "components/Common/Notification"
import { post } from "helpers/api_methods"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  userLoginFail,
  userLoginSuccess,
  userLogoutFail,
  userLogoutSuccess,
} from "./actions"
import { LOGIN_USER_REQUEST, LOGOUT } from "./actionTypes"

const loginApi = input => {
  return post("/account/login", input)
}
function* userLogin({ payload: { input, history } }) {
  try {
    const res = yield call(loginApi, input)
    if (res?.token) {
      localStorage.setItem("token", res?.token)
      localStorage.setItem("is_admin", res?.is_admin)
      yield put(userLoginSuccess(res))
      useSuccessNotification("Logged In Successfully!")
      history.push("/dashboard")
    }
  } catch (error) {
    yield put(userLoginFail(error?.data))
  }
}

const userLogoutApi = () => {
  return post("/account/logout")
}
function* userLogout({ payload: { history } }) {
  try {
    const res = yield call(userLogoutApi)
    localStorage.removeItem("token", res?.token)
    localStorage.removeItem("is_admin", res?.is_admin)
    yield put(userLogoutSuccess(res))
    useSuccessNotification("Logged Out Successfully!")
    history.push("/login")
  } catch (error) {
    yield put(userLogoutFail(error?.data))
  }
}

function* loginSaaga() {
  yield takeEvery(LOGIN_USER_REQUEST, userLogin)
  yield takeEvery(LOGOUT, userLogout)
}

export default loginSaaga
