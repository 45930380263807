import React from "react"
import { Card, CardBody, CardTitle } from "reactstrap"

import "assets/scss/datatables.scss"
import Transaction from "./Table"

function TransactionTable() {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle>Transaction History</CardTitle>
          <Transaction />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default TransactionTable
