import {
  CREATE_ACCOUNT,
  CREATE_ACCOUNTS_SUCCESS,
  CREATE_ACCOUNT_FAIL,
  GET_ALL_ACCOUNTS,
  GET_ALL_ACCOUNTS_SUCCESS,
  GET_ALL_ACCOUNTS_FAIL,
  GET_ALL_GYM,
  GET_ALL_GYM_SUCCESS,
  GET_ALL_GYM_FAIL,
  CREATE_GYM,
  CREATE_GYM_SUCCESS,
  CREATE_GYM_FAIL,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_FAIL,
  DELETE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAIL,
  UPDATE_GYM,
  UPDATE_GYM_SUCCESS,
  UPDATE_GYM_FAIL,
  DELETE_GYM,
  DELETE_GYM_SUCCESS,
  DELETE_GYM_FAIL,
  GET_STATES,
  CREATE_STATES_SUCCESS,
  CREATE_STATES_FAIL,
  UPDATE_STATES_FAIL,
  DELETE_STATES_FAIL,
  UPDATE_STATES_SUCCESS,
  GET_STATES_SUCCESS,
  GET_STATES_FAIL,
  DELETE_STATES_SUCCESS,
  GET_DISTRICTS,
  GET_DISTRICTS_FAIL,
  GET_DISTRICTS_SUCCESS,
  CREATE_DISTRICT_SUCCESS,
  UPDATE_DISTRICTS_SUCCESS,
  DELETE_DISTRICTS_SUCCESS,
  CREATE_STATES,
  UPDATE_STATES,
  DELETE_STATES,
  CREATE_DISTRICT,
  UPDATE_DISTRICTS,
  DELETE_DISTRICTS,
  GET_SINGLE_ACCOUNTS,
  GET_SINGLE_ACCOUNTS_FAIL,
  GET_SINGLE_ACCOUNTS_SUCCESS,
  GET_SINGLE_GYM,
  GET_SINGLE_GYM_FAIL,
  GET_SINGLE_GYM_SUCCESS,
  GYM_SMS_RECHARGE,
  GYM_SMS_RECHARGE_FAIL,
  GYM_SMS_RECHARGE_SUCCESS,
  GET_RECHARGE_REQUEST,
  GET_RECHARGE_REQUEST_FAIL,
  GET_RECHARGE_REQUEST_SUCCESS,
  UPDATE_RECHARGE_REQUEST_SUCCESS,
  UPDATE_RECHARGE_REQUEST,
  UPDATE_RECHARGE_REQUEST_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  error: {},
  loading: false,

  gyms: {
    count: "",
    results: [],
  },
  singleGym: {},
  gymLoad: false,

  users: {
    count: "",
    results: [],
  },
  userDetails: {},
  userLoad: false,

  states: {
    count: "",
    results: [],
  },
  stateLoad: false,

  districts: {
    count: "",
    results: [],
  },
  districtsLoad: false,

  rechargeRequest: {
    results: [],
  },
  rechargeLoad: true,
}

const Fitcom = (state = INIT_STATE, action) => {
  switch (action.type) {
    //LOADING
    case GET_ALL_ACCOUNTS:
    case GET_SINGLE_ACCOUNTS:
    case GET_ALL_GYM:
    case GET_SINGLE_GYM:
    case GET_STATES:
    case GET_DISTRICTS:
    case GYM_SMS_RECHARGE:
    case GET_RECHARGE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_RECHARGE_REQUEST:
      return {
        ...state,
        rechargeLoad: true,
      }

    //FAIL
    case GET_RECHARGE_REQUEST_FAIL:
    case UPDATE_RECHARGE_REQUEST_FAIL:
    case GYM_SMS_RECHARGE_FAIL:
    case GET_ALL_ACCOUNTS_FAIL:
    case GET_SINGLE_ACCOUNTS_FAIL:
    case GET_ALL_GYM_FAIL:
    case CREATE_ACCOUNT_FAIL:
    case UPDATE_ACCOUNT_FAIL:
    case DELETE_ACCOUNT_FAIL:
    case CREATE_GYM_FAIL:
    case UPDATE_GYM_FAIL:
    case DELETE_GYM_FAIL:
    case GET_STATES_FAIL:
    case CREATE_STATES_FAIL:
    case UPDATE_STATES_FAIL:
    case DELETE_STATES_FAIL:
    case GET_DISTRICTS_FAIL:
    case GET_SINGLE_GYM_FAIL:
      return {
        ...state,
        error: action.payload,
        stateLoad: false,
        districtsLoad: false,
        userLoad: false,
        loading: false,
      }

    //GYM
    case CREATE_GYM:
    case UPDATE_GYM:
    case DELETE_GYM:
      return {
        ...state,
        gymLoad: true,
      }
    case GET_ALL_GYM_SUCCESS:
      return {
        ...state,
        gyms: action.payload,
        error: {},
        loading: false,
      }

    case GET_SINGLE_GYM_SUCCESS:
      return {
        ...state,
        singleGym: action.payload,
        loading: false,
        error: {},
      }

    case CREATE_GYM_SUCCESS:
      return {
        ...state,
        singleGym: action.payload,
        gymLoad: false,
        error: {},
      }

    case UPDATE_GYM_SUCCESS:
      return {
        ...state,
        gyms: {
          ...state.gyms,
          results: state.gyms.results?.map(gym =>
            gym.id === action.payload.id ? { ...gym, ...action.payload } : gym
          ),
        },
        singleGym: action.payload,
        gymLoad: false,
        error: {},
      }

    case DELETE_GYM_SUCCESS:
      return {
        ...state,
        gyms: {
          ...state.gyms,
          results: state.gyms.results?.filter(gym => gym.id !== action.payload),
        },
        gymLoad: false,
        error: {},
      }
    case GYM_SMS_RECHARGE_SUCCESS:
      return {
        ...state,
        loading: false,
        singleGym: {
          ...state.singleGym,
          sms_balance: state.singleGym.sms_balance + action.payload.sms_amount,
        },
        gyms: {
          ...state.gyms,
          results: state.gyms.results?.map(gym =>
            gym.id === action.payload.gym
              ? {
                  ...gym,
                  sms_balance: gym.sms_balance + action.payload.sms_amount,
                }
              : gym
          ),
        },
        error: {},
      }

    //account
    case DELETE_ACCOUNT:
    case UPDATE_ACCOUNT:
    case CREATE_ACCOUNT:
      return {
        ...state,
        userLoad: true,
      }
    case GET_ALL_ACCOUNTS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        error: {},
        loading: false,
      }
    case GET_SINGLE_ACCOUNTS_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_ACCOUNTS_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
        error: {},
        userLoad: false,
      }

    case UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        error: {},
        userLoad: false,
        userDetails: action.payload,
      }

    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          results: state.users?.results?.filter(
            room => room.id !== action.payload
          ),
        },
        userLoad: false,
        error: {},
      }

    //state
    case CREATE_STATES:
    case UPDATE_STATES:
    case DELETE_STATES:
      return {
        ...state,
        stateLoad: true,
      }
    case GET_STATES_SUCCESS:
      return {
        ...state,
        states: action.payload,
        loading: false,
        error: {},
      }
    case CREATE_STATES_SUCCESS:
      return {
        ...state,
        states: {
          ...state.states,
          results: [...state.states.results, action.payload],
        },
        stateLoad: false,
        error: {},
      }
    case UPDATE_STATES_SUCCESS:
      return {
        ...state,
        states: {
          ...state.states,
          results: state.states.results?.map(state =>
            state.id === action.payload.id ? action.payload : state
          ),
        },
        stateLoad: false,
        error: {},
      }
    case DELETE_STATES_SUCCESS:
      return {
        ...state,
        states: {
          ...state.states,
          results: state.states.results?.filter(
            state => state.id !== action.payload
          ),
        },
        stateLoad: false,
        error: {},
      }

    //district
    case CREATE_DISTRICT:
    case UPDATE_DISTRICTS:
    case DELETE_DISTRICTS:
      return {
        ...state,
        districtsLoad: true,
      }
    case GET_DISTRICTS_SUCCESS:
      return {
        ...state,
        districts: action.payload,
        loading: false,
        error: {},
      }
    case CREATE_DISTRICT_SUCCESS:
      return {
        ...state,
        districts: {
          ...state.districts,
          results: [...state.districts.results, action.payload],
        },
        districtsLoad: false,
        error: {},
      }
    case UPDATE_DISTRICTS_SUCCESS:
      return {
        ...state,
        districts: {
          ...state.districts,
          results: state.districts.results?.map(district =>
            district.id === action.payload.id ? action.payload : district
          ),
        },
        districtsLoad: false,
        error: {},
      }
    case DELETE_DISTRICTS_SUCCESS:
      return {
        ...state,
        districts: {
          ...state.districts,
          results: state.districts.results?.filter(
            district => district.id !== action.payload
          ),
        },
        districtsLoad: false,
        error: {},
      }

    case GET_RECHARGE_REQUEST_SUCCESS:
      return {
        ...state,
        rechargeRequest: action.payload,
        loading: false,
      }
    case UPDATE_RECHARGE_REQUEST_SUCCESS:
      return {
        ...state,
        rechargeRequest: {
          ...state.rechargeRequest,
          results: state.rechargeRequest?.results?.map(i =>
            i.id === action?.payload.id ? { ...i, ...action.payload } : i
          ),
        },
        rechargeLoad: false,
      }

    default:
      return state
  }
}

export default Fitcom
