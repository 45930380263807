import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  CardTitle,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { map, range } from "lodash"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import "assets/scss/datatables.scss"
import MyPagination from "components/Common/MyPagination"
import { deleteDistrict, getDistricts } from "store/actions"
import DistrictModal from "./crud/DistrictModal"
import DeleteModal from "components/Common/DeleteModal"

const DistrictDataTable = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)

  const { districts, loading, districtsLoad } = useSelector(state => ({
    loading: state.Fitcom.loading,
    districtsLoad: state.Fitcom.districtsLoad,
    districts: state.Fitcom.districts,
  }))

  const [updateModal, setUpdateModal] = useState({
    status: false,
    data: {},
  })
  const [deleteItem, setDeleteItem] = useState({ status: false, id: "" })

  //pages
  const totalPages = Math.ceil(districts?.count / 10)
  const pages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(getDistricts(searchText, page))
  }, [dispatch, page, searchText])

  const columns = [
    {
      dataField: "auto_id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "district_name",
      text: "District Name",
    },
    {
      dataField: "state_name",
      text: "State Name",
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  const handleDelete = id => {
    setDeleteItem({
      status: true,
      id: id,
    })
  }
  const handleDeleteEvent = () => {
    dispatch(deleteDistrict(deleteItem?.id))
    setDeleteItem({ status: false, id: "" })
  }

  const roomTypesData = map(districts?.results, (item, index) => ({
    ...item,
    key: index,
    action: (
      <div>
        <Link
          to={`#!`}
          onClick={() =>
            setUpdateModal({ ...updateModal, status: true, data: item })
          }
          className="text-info mx-1 btn-sm"
        >
          <i className="fas fa-pen"></i>
        </Link>

        <Link
          onClick={() => handleDelete(item?.id)}
          to={`#`}
          className="text-danger mx-1 btn-sm"
        >
          <i className="fas fa-trash"></i>
        </Link>
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }
  // /shop/make
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteItem.status}
        onCloseClick={() => setDeleteItem({ status: false, id: "" })}
        onDeleteClick={handleDeleteEvent}
        loading={districtsLoad}
      />
      <DistrictModal
        onCloseClick={() => setUpdateModal({ status: false, data: {} })}
        show={updateModal.status}
        data={updateModal.data}
      />
      <Row>
        <Col className="col-12 col-lg-8">
          <Card>
            <CardBody>
              <CardTitle>Districts </CardTitle>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={roomTypesData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e => handleSearch(e)}
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search..."
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                      <Col md="4"></Col>
                      <Col
                        md="4"
                        className="d-flex align-items-center justify-content-end"
                      >
                        <Link
                          to="#"
                          onClick={() =>
                            setUpdateModal({
                              ...updateModal,
                              status: true,
                              data: {},
                            })
                          }
                        >
                          <Button
                            type="submit"
                            color="success"
                            className="w-md"
                          >
                            Add District
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap custom-action"
                                }
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default DistrictDataTable
