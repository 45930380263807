import MetaTag from "components/Common/Meta-tag"
import React from "react"
import { Container } from "reactstrap"
import DatatableTables from "./DatatableTables"
import Breadcrumbs from "components/Common/Breadcrumb"

function Accounts() {
  return (
    <>
      <MetaTag title="Users" />

      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="Users" />
        <Container fluid>
          <div className="container-fluid">
            <DatatableTables />
          </div>
        </Container>
      </div>
    </>
  )
}

export default Accounts
