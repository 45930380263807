import {
  LOGIN_USER_FAIL,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGOUT_FAIL,
  LOGOUT,
  LOGOUT_SUCCESS,
} from "./actionTypes"

// Login  //
export const userLoginRequest = (input, history) => ({
  type: LOGIN_USER_REQUEST,
  payload: { input, history },
})

export const userLoginSuccess = input => ({
  type: LOGIN_USER_SUCCESS,
  payload: input,
})
export const userLoginFail = error => ({
  type: LOGIN_USER_FAIL,
  payload: error,
})

// Register  //
export const userLogout = history => ({
  type: LOGOUT,
  payload: { history },
})

export const userLogoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
})

export const userLogoutFail = error => ({
  type: LOGOUT_FAIL,
  payload: error,
})
