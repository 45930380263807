import React, { useEffect, useState } from "react"
import { Button, Col, FormGroup, Modal, ModalBody, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import { createDistrict, getStates, updateDistrict } from "store/Gym/actions"
import PropTypes from "prop-types"
import Spinner from "components/Common/Spinner"
import { IoCloseOutline } from "react-icons/io5"
import { debounce, isEmpty } from "lodash"
import Select from "react-select"

function DistrictModal({ onCloseClick, show, data }) {
  const dispatch = useDispatch()

  const { districtsLoad, states, loading } = useSelector(state => ({
    districtsLoad: state.Fitcom.districtsLoad,
    loading: state.Fitcom.loading,
    states: state.Fitcom.states,
  }))

  console.log(data)

  //District dropdown
  const [stateSeclect, setStateSeclect] = useState({
    id: "",
    title: "Search States",
  })
  const [stateSearch, setStatesSearch] = useState()

  const debounceStatesSearch = debounce(value => setStatesSearch(value), 600)
  const handleStatesEnters = textEntered => {
    debounceStatesSearch(textEntered)
  }

  function handlerStatesFinalValue(event) {
    const states = {
      ...stateSeclect,
      title: event.label,
      id: event.value,
    }
    setStateSeclect(states)
  }

  const statesOptions =
    states?.results &&
    states?.results?.map((result, index) => ({
      key: index,
      label: result.state_name,
      value: result?.id,
    }))

  useEffect(() => {
    dispatch(getStates("", "", stateSearch))
  }, [stateSearch])
  //end of account dropdown

  useEffect(() => {
    if (!isEmpty(data)) {
      const states = {
        ...stateSeclect,
        title: data.state_name,
        id: data.state,
      }
      setStateSeclect(states)
    }
  }, [data])

  const handleSubmit = (e, value) => {
    if (isEmpty(data)) {
      dispatch(
        createDistrict({ ...value, state: stateSeclect.id }, onCloseClick)
      )
    } else {
      dispatch(
        updateDistrict(
          { ...value, state: stateSeclect.id },
          data?.id,
          onCloseClick
        )
      )
    }
  }

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="p-4">
        <p className="mb-0 mt-3">
          {isEmpty(data) ? "Create" : "Update"} District
        </p>
        <IoCloseOutline className="close-icon" onClick={onCloseClick} />

        <AvForm
          onValidSubmit={(e, input) => {
            handleSubmit(e, input)
          }}
        >
          <Row className="mt-2">
            <FormGroup className="mb-3">
              <label>State :</label>
              <div className="mb-3 ajax-select mt-lg-0 select2-container">
                <Select
                  onInputChange={handleStatesEnters}
                  value={stateSeclect?.title}
                  placeholder={stateSeclect?.title}
                  onChange={handlerStatesFinalValue}
                  options={statesOptions}
                  classNamePrefix="select2-selection"
                  isLoading={loading}
                />
              </div>
            </FormGroup>

            <Col className={`col-12`}>
              <AvField
                name="district_name"
                label=""
                type="text"
                value={data?.district_name ?? ""}
                required
                placeholder="Enter District name"
              />
            </Col>

            <Col
              className={`d-flex align-items-start col-12 justify-content-end mt-3`}
            >
              <FormGroup className="mb-3">
                <label>&nbsp;</label>
                <div>
                  <Button outline type="submit" color="success">
                    {isEmpty(data) ? "Create" : "Update"} District
                    {districtsLoad && <Spinner className="mx-1" />}
                  </Button>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

export default DistrictModal

DistrictModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.bool,
  data: PropTypes.any,
}
