import Spinner from "components/Common/Spinner"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, CardBody, Input, InputGroup, Label } from "reactstrap"
import { gymSMSRecharge } from "store/actions"
import PropTypes from "prop-types"
import { BiPhone } from "react-icons/bi"

function SMSCard({ onCloseClick, isModal }) {
  const dispatch = useDispatch()

  const { singleGym, loading } = useSelector(state => ({
    singleGym: state.Fitcom.singleGym,
    loading: state.Fitcom.loading,
  }))

  const [smsCount, setSMSCount] = useState(0)

  const handleRecgargeSms = () => {
    const finalData = {
      gym: singleGym?.id,
      sms_amount: parseInt(smsCount),
    }
    if (isModal) dispatch(gymSMSRecharge(finalData, onCloseClick))
    else dispatch(gymSMSRecharge(finalData))
  }
  return (
    <CardBody className="p-4">
      {isModal ? (
        <h4 className="card-title mb-4">
          SMS{" "}
          <span style={{ fontWeight: "300" }} className="font-size-12">
            ({singleGym?.gym_name},{" "}
            <span className="font-size-12" style={{ fontWeight: "300" }}>
              <BiPhone /> {singleGym?.phone}
            </span>
            )
          </span>
        </h4>
      ) : (
        <h4 className="card-title mb-4">SMS</h4>
      )}
      <div>
        <p className="text-muted mb-2">
          <i className="mdi mdi-wallet me-1" /> SMS Balance (NOS)
        </p>
        <h5>{singleGym?.sms_balance}</h5>
      </div>

      <div className="mt-4">
        <div>
          <div className="mb-3">
            <Label>Recharge SMS:</Label>
            <InputGroup className="mb-3">
              <Label className="input-group-text">SMS Count</Label>
              <Input
                type="text"
                className="form-control"
                onChange={e => setSMSCount(e.target.value)}
              />
            </InputGroup>
          </div>

          {isModal ? (
            <div className="hstack gap-2 mt-4">
              <button
                className="btn btn-outline-dark w-100"
                style={{ borderRadius: 10 }}
                onClick={onCloseClick}
              >
                Close
              </button>
              <Button
                type="button"
                color="success"
                className="w-100"
                onClick={handleRecgargeSms}
                style={{ borderRadius: 10 }}
              >
                Recharge
                {loading && <Spinner />}
              </Button>
            </div>
          ) : (
            <div className="text-center">
              <Button
                type="button"
                color="success"
                className="w-md"
                onClick={handleRecgargeSms}
              >
                Recharge
                {loading && <Spinner />}
              </Button>
            </div>
          )}
        </div>
      </div>
    </CardBody>
  )
}

export default SMSCard

SMSCard.propTypes = {
  onCloseClick: PropTypes.func,
  isModal: PropTypes.bool,
}
