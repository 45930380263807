import { AvField } from "availity-reactstrap-validation"
import React from "react"
import { Col, Row } from "reactstrap"
import ProTypes from "prop-types"
import ImageCreate from "./ImageCreate"

function UserForm({ updateValue, setImage }) {
  return (
    <div>
      <Row className="py-2">
        <Col md={6}>
          <AvField
            label="First Name"
            value={updateValue ? updateValue?.first_name : ""}
            name="first_name"
            type="text"
            required
          />
        </Col>
        <Col md={6} className="sm:mt-2">
          <AvField
            label="Last Name"
            name="last_name"
            value={updateValue ? updateValue?.last_name : ""}
            type="text"
          />
        </Col>
        <Col md={6} className="mt-2">
          <AvField
            label="Phone"
            name="phone"
            value={updateValue ? updateValue?.phone : ""}
            type="number"
          />
        </Col>
        <Col md={6} className="mt-2">
          <AvField
            label="Email"
            name="email"
            value={updateValue ? updateValue?.email : ""}
            type="email"
          />
        </Col>
        <Col md={6} className="mt-2">
          <AvField
            style={{ height: "100px" }}
            label="Address"
            name="address"
            value={updateValue ? updateValue?.address : ""}
            type="textarea"
          />
        </Col>
        <Col md={6} className="mt-2">
          <p className="text-muted start mb-0"> Profile Pic </p>
          <ImageCreate
            setImage={setImage}
            image={updateValue ? updateValue.profile_pic : ""}
          />
        </Col>
      </Row>
    </div>
  )
}

export default UserForm

UserForm.propTypes = {
  updateValue: ProTypes.object,
  setImage: ProTypes.func,
}
