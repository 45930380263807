import MetaTag from "components/Common/Meta-tag"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Container } from "reactstrap"
import AdminDashboard from "./admin"

function index() {
  const dispatch = useDispatch()
  useEffect(() => {}, [dispatch])

  const isAdmin = localStorage.getItem("is_admin")
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTag title="Dashboard" />
        <Container fluid>{isAdmin && <AdminDashboard />}</Container>
      </div>
    </React.Fragment>
  )
}

export default index
