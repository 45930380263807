import React, { useEffect, useState } from "react"
import MetaTag from "components/Common/Meta-tag"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import Spinner from "components/Common/Spinner"
import GymForm from "../From/Form"
import { getSingleGym, updateGym } from "store/actions"
import SMSCard from "../Eelements/SMSCard"
import TransactionTable from "../GymTransaction/TransactionTable"

const UpdateGym = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const { loading, singleGym } = useSelector(state => ({
    loading: state.Fitcom.gymLoad,
    singleGym: state.Fitcom.singleGym,
  }))

  const [districtSeclect, setDistrictSeclect] = useState({
    id: "",
    title: "Search District",
  })
  const [accountSeclect, setAccountSeclect] = useState({
    id: "",
    title: "Search Users",
  })

  useEffect(() => {
    dispatch(getSingleGym(params.id))
  }, [params.id])

  useEffect(() => {
    const account = {
      ...accountSeclect,
      title: singleGym?.first_name,
      id: singleGym?.account,
    }
    setAccountSeclect(account)
  }, [singleGym])

  useEffect(() => {
    const district = {
      ...districtSeclect,
      title: singleGym.district_name,
      id: singleGym.district,
    }
    setDistrictSeclect(district)
  }, [singleGym])

  const handleGymSubmit = (_, values) => {
    const finalData = {
      ...values,
      account: accountSeclect.id,
      district: districtSeclect.id,
    }
    dispatch(updateGym(finalData, singleGym?.id, history))
  }

  return (
    <>
      <MetaTag title="Fitness Center Update" />

      <div className="page-content">
        <Breadcrumbs
          title="Fitness Centers"
          breadcrumbItem="Fitness Center Updated"
        />
        <Container fluid>
          <div className="container-fluid ">
            <Row>
              <Col className="col-8">
                <Card>
                  <CardBody>
                    <AvForm
                      onValidSubmit={(e, v) => {
                        handleGymSubmit(e, v)
                      }}
                    >
                      <GymForm
                        districtSeclect={districtSeclect}
                        setDistrictSeclect={setDistrictSeclect}
                        accountSeclect={accountSeclect}
                        setAccountSeclect={setAccountSeclect}
                        updateValue={singleGym}
                      />
                      <div className="d-flex justify-content-end mt-5">
                        <Button color="success" className="px-4" type="submit">
                          Update Gym {loading && <Spinner />}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
                <TransactionTable />
              </Col>

              <Col xl="4">
                <Card>
                  <SMSCard />
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateGym
