import MetaTag from "components/Common/Meta-tag"
import React from "react"
import { Container } from "reactstrap"
import DatatableTables from "./DatatableTables"
import Breadcrumbs from "components/Common/Breadcrumb"

function States() {
  return (
    <>
      <MetaTag title="States" />

      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="States" />
        <Container fluid>
          <div className="container-fluid">
            <DatatableTables />
          </div>
        </Container>
      </div>
    </>
  )
}

export default States
