import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardSubtitle, Button } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { map, range } from "lodash"

import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

//component
import Spinner from "components/Common/Spinner"

//css
import "assets/scss/datatables.scss"

//images
import {
  getRechargeRequest,
  updateRechargeRequest,
  updateRechargeRequestSuccess,
} from "store/actions"
import MyPagination from "components/Common/MyPagination"

const RechargeTable = () => {
  const dispatch = useDispatch()

  const { loading, rechargeRequest, rechargeLoad } = useSelector(state => ({
    loading: state.Fitcom.loading,
    rechargeRequest: state.Fitcom.rechargeRequest,
    rechargeLoad: state.Fitcom.rechargeLoad,
  }))

  const [page, setPage] = useState(1)
  //pages
  const totalPages = Math.ceil(rechargeRequest?.count / 10)
  const pages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(getRechargeRequest(page, 10))
  }, [dispatch, page])

  const columns = [
    {
      dataField: "auto_id",
      text: "#",
      sort: true,
    },
    {
      dataField: "gym_name",
      text: "Gym Name",
      sort: true,
    },
    {
      dataField: "sms_amount",
      text: "SMS amount",
      sort: true,
    },
    {
      dataField: "status_display",
      filterText: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "status",
      filterText: "status",
      text: "action",
      sort: true,
    },
  ]

  const handleUpdateStatus = (id, status) => {
    dispatch(updateRechargeRequest(id, status))
  }

  //all
  const tableData = map(rechargeRequest?.results, (item, index) => ({
    ...item,
    key: index,
    status: (
      <div className="d-flex align-items-center ">
        <Button
          type="button"
          color="success"
          outline
          disabled={item?.status === "accept"}
          className="w-100 me-3 btn-sm "
          onClick={() => handleUpdateStatus(item?.id, "accept")}
        >
          Accept
        </Button>
        <Button
          type="button"
          color="light"
          disabled={item?.status === "reject"}
          className="w-100 btn-sm"
          onClick={() => handleUpdateStatus(item?.id, "reject")}
        >
          Reject
        </Button>
      </div>
    ),
  }))

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardSubtitle className="mb-3">Recharged request</CardSubtitle>
              <div>
                <ToolkitProvider
                  keyField="id"
                  columns={columns}
                  data={tableData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <>
                        {rechargeRequest?.results?.length >= 1 ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No Recharged Requests !</p>
                        )}
                      </>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default RechargeTable
