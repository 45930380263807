import React from "react"
import { map } from "lodash"
import { Col, Input, Row } from "reactstrap"
import { useHistory, useLocation } from "react-router-dom"
import PropTypes from "prop-types"

const MyPagination = ({ totalPages, page, setPage }) => {
  console.log(totalPages)
  const history = useHistory()
  const location = useLocation()

  const [pageNumber, setPageNumber] = React.useState(location?.state?.page ?? 1)

  const apiPage = () => {
    if (page >= totalPages.length) {
      return totalPages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const showOnlyFive =
    totalPages.length > 5 && page > 5
      ? totalPages.slice(page - 6, page + 5)
      : totalPages.slice(0, page + 5)

  const handleNextClick = () => {
    setPage(page + 1)
    history.push({
      pathname: location.pathname,
      search: `p=${page + 1}`,
      state: {
        page: page + 1,
      },
    })
  }
  const handlePrevClick = () => {
    setPage(page - 1)
    history.push({
      pathname: location.pathname,
      search: `p=${page - 1}`,
      state: {
        page: page - 1,
      },
    })
  }
  const handleNumClick = item => {
    setPage(item)
    history.push({
      pathname: location.pathname,
      search: `p=${item}`,
      state: {
        page: item,
      },
    })
  }

  React.useEffect(() => {
    if (location?.state && location?.state?.page) {
      setPage(location?.state?.page)
    }
  }, [location?.state?.page])

  const handleSubmit = e => {
    e.preventDefault()
    if (pageNumber <= totalPages) {
      setPage(pageNumber)
      history.push({
        pathname: location.pathname,
        search: `p=${pageNumber}`,
        state: {
          page: pageNumber,
        },
      })
    } else {
      setPage(page)
      setPageNumber(page)
      history.push({
        pathname: location.pathname,
        search: `p=${page}`,
        state: {
          page: page,
        },
      })
    }
  }

  return (
    <Row
      className="align-items-md-center  mt-30 "
      style={{ marginTop: "2rem" }}
    >
      <Col
        style={{
          background: "#EFF5F5",
          padding: 12,
          borderRadius: 8,
        }}
        className="align-items-center inner-custom-pagination  d-flex pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination"
      >
        <form
          onSubmit={handleSubmit}
          className="d-flex align-items-center"
          style={{ flex: 1 }}
        >
          <p className="p-0 m-0 pe-1 font-size-12">Page </p>
          <Input
            name="page"
            type="number"
            style={{ width: 45, height: 30, border: "1px solid green" }}
            value={pageNumber}
            onChange={e => setPageNumber(e.target.value)}
          />
          <p className="p-0 m-0 px-1 font-size-12"> of {totalPages?.length}</p>
          <button
            type="submit"
            style={{ width: 60, height: 30, border: "1px solid green" }}
            className={`ms-2 btn tickbtn d-flex align-items-center justify-contetn-center my-custom-success-soft btn-success`}
            // onClick={() => handleNumClick(pageNumber)}
          >
            Go
            <i className="fas fa-angle-right ms-1"></i>
          </button>
        </form>
        <div className="text-md-right overflowScroll">
          {page <= 1 ? (
            <></>
          ) : (
            <div
              className="btn-group me-0 "
              role="group"
              aria-label="First group"
            >
              <span
                style={{
                  borderRadius: "50%",
                  border: "none",
                }}
                className="btn btn-outline-light text-info "
                onClick={handlePrevClick}
              >
                <i className="fas fa-angle-left"></i>
              </span>
            </div>
          )}
          <div
            className="btn-group me-2 "
            role="group"
            aria-label="Second group"
          >
            {map(showOnlyFive, (item, index) => (
              <span
                key={index}
                className="btn btn-outline-info"
                onClick={() => handleNumClick(item)}
                style={{
                  borderRadius: "50%",
                  marginLeft: "5px",
                  marginRight: "5px",
                  border: "none",
                  backgroundColor: apiPage() == item && "#66c2ff",
                  color: apiPage() == item && "#fff",
                }}
              >
                {item}
              </span>
            ))}
          </div>
          {page >= totalPages?.length ? (
            <></>
          ) : (
            <div className="btn-group" role="group" aria-label="Third group">
              <span
                className="btn btn-outline-light text-info"
                style={{
                  borderRadius: "50%",
                  border: "none",
                }}
                onClick={handleNextClick}
              >
                <i className="fas fa-angle-right"></i>
              </span>
            </div>
          )}
        </div>
      </Col>
    </Row>
  )
}

MyPagination.propTypes = {
  totalPages: PropTypes.array,
  page: PropTypes.any,
  setPage: PropTypes.func,
}

export default MyPagination
