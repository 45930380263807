import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import logo from "assets/images/logo/logo.png"
import { userLoginRequest } from "store/actions"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import MetaTag from "components/Common/Meta-tag"
import Spinner from "components/Common/Spinner"

const Login = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [showPass, setShowPass] = useState(false)

  const { loading, error } = useSelector(state => ({
    loading: state.Login.loading,
    error: state.Login.error,
  }))

  const handleLogin = (e, input) => {
    dispatch(userLoginRequest(input, history))
  }

  return (
    <React.Fragment>
      <MetaTag title="Login" />

      <Container
        className="p-5 rounded-circle d-flex flex-column justify-content-center"
        style={{ height: "100vh" }}
      >
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden shadow-rounded">
              <CardBody className="pt-0">
                <div className="mt-3 d-flex justify-content-center aling-items-center pt-4">
                  <div className="profile-user-wid d-flex align-items-center justify-content-center py-3">
                    <img alt={"logo"} height="30" width={"100%"} src={logo} />
                  </div>
                </div>
                <p
                  className="p-2 pb-0 font-size-18 mb-4 text-center text-black"
                  style={{ fontWeight: "500" }}
                >
                  LOGIN
                </p>
                <div className="p-2">
                  <AvForm
                    onValidSubmit={(e, input) => {
                      handleLogin(e, input)
                    }}
                  >
                    <div className="mb-3">
                      <AvField
                        name="username"
                        label="Username"
                        className="form-control"
                        placeholder="Enter username"
                        type="text"
                        required
                      />
                    </div>

                    <div className="mb-3 position-relative">
                      <AvField
                        name="password"
                        label="Password"
                        type={showPass ? "text" : "password"}
                        required
                        placeholder="Enter Password"
                      />
                      <div
                        className="w-fit rounded position-absolute d-flex p-2 bg-light align-items-center justify-content-center"
                        style={{
                          right: "1px",
                          bottom: "1px",
                          height: "35px",
                          width: "40px",
                          cursor: "pointer",
                        }}
                        onClick={() => setShowPass(!showPass)}
                      >
                        {showPass ? (
                          <i className="far fa-eye-slash mx-1"></i>
                        ) : (
                          <i className="far fa-eye mx-1"></i>
                        )}
                      </div>
                    </div>
                    <span className="text-danger">{error?.error_message}</span>

                    {/* <p className="pl-2 pb-2 pt-5 ml-10px">
                      Don&#39;t have an account ?{" "}
                      <Link to="/register" className="fw-medium text-primary">
                        {" "}
                        Create now{" "}
                      </Link>{" "}
                    </p> */}

                    <div className="mt-4 d-grid">
                      <button
                        className="btn btn-success btn-block"
                        type="submit"
                      >
                        {loading && <Spinner />}
                        Log In
                      </button>
                    </div>

                    {/* <div className="mt-4 text-center">
                      <Link to="/forgetpassword" className="text-muted">
                        <i className="mdi mdi-lock me-1" />
                        Forgot your password?
                        &nbsp;
                      </Link>
                    </div> */}
                  </AvForm>
                </div>
              </CardBody>
            </Card>
            <div className="mt-5 text-center">
              <p>
                © {new Date().getFullYear()} Fitcom | All Right Issued | Crafted
                with <i className="mdi mdi-heart text-danger" /> by{" "}
                <a href="https://osperb.com/"> Osperb</a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default Login

Login.propTypes = {
  history: PropTypes.object,
}
