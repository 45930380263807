import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  CardTitle,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { map, range } from "lodash"
import DeleteModal from "components/Common/DeleteModal"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import "../../assets/scss/datatables.scss"
import MyPagination from "components/Common/MyPagination"
import { deleteAccount, getAccount } from "store/Gym/actions"

const AccountsList = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)

  const { users, loading, userLoad } = useSelector(state => ({
    users: state.Fitcom.users,
    loading: state.Fitcom.loading,
    userLoad: state.Fitcom.userLoad,
  }))

  const [deleteItem, setDeleteItem] = useState({ status: false, id: "" })

  //pages
  const totalPages = Math.ceil(users?.count / 10)
  const pages = range(1, totalPages + 1)

  console.log(users)
  useEffect(() => {
    dispatch(getAccount(page, 10, searchText))
  }, [dispatch, page, searchText])

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    // {
    //   dataField: "profile_pic",
    //   text: "Profile Pic",
    //   sort: true,
    // },

    {
      dataField: "first_name",
      text: "First Name",
    },
    {
      dataField: "last_name",
      text: "Last Name",
    },
    {
      dataField: "phone",
      text: "Phone ",
    },
    {
      dataField: "email",
      text: "Email",
    },
    // {
    //   dataField: "address",
    //   text: "Address",
    // },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  const handleDelete = id => {
    setDeleteItem({
      status: true,
      id: id,
    })
  }
  const handleDeleteEvent = () => {
    dispatch(deleteAccount(deleteItem?.id))
    setDeleteItem({ status: false, id: "" })
  }

  const usersData = map(users?.results, (item, index) => ({
    ...item,
    key: index,
    // address: (
    //   <p
    //     className="m-0 p-0"
    //     style={{
    //       width: "400px",
    //       whiteSpace: "normal",
    //     }}
    //   >
    //     {item?.address}
    //   </p>
    // ),
    // profile_pic: (
    //   <>
    //     <img
    //       src={`${item?.profile_pic}`}
    //       alt={"user-avatar"}
    //       className="avatar-sm rounded me-3"
    //     />
    //   </>
    // ),
    action: (
      <div>
        <Link to={`/accounts/${item.id}`} className="text-info mx-1 btn-sm">
          <i className="far fa-eye"></i>
        </Link>

        <Link
          onClick={() => handleDelete(item.id)}
          to={`#`}
          className="text-danger mx-1 btn-sm"
        >
          <i className="fas fa-trash"></i>
        </Link>
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }
  // /shop/make

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteItem.status}
        onCloseClick={() => setDeleteItem({ status: false, id: "" })}
        onDeleteClick={handleDeleteEvent}
        loading={userLoad}
      />

      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle>Users</CardTitle>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={usersData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e => handleSearch(e)}
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search..."
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                      <Col md="4"></Col>
                      <Col
                        md="4"
                        className="d-flex align-items-center justify-content-end"
                      >
                        <Link to="/accounts/create">
                          <Button
                            type="submit"
                            color="success"
                            className="w-md"
                          >
                            Add User
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap floting-action"
                                }
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AccountsList
