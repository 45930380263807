import ThumbSlider from "components/Common/Thumb"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Card, CardBody, CardTitle, Col, Row, Spinner } from "reactstrap"
import { getAccount, getGym, getYearlyChart } from "store/actions"
import MiniWidget from "./elements/mini-widget"
import BarChart from "components/charts/barchart"
import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import RechargeTable from "./elements/rechargeTable"

function AdminDashboard() {
  const dispatch = useDispatch()
  const { loading, gyms, users, yearGymLoading, yearlyGymData } = useSelector(
    state => ({
      loading: state.Fitcom.loading,
      gyms: state.Fitcom.gyms,
      users: state.Fitcom.users,
      yearGymLoading: state.Dashboard.yearGymLoading,
      yearlyGymData: state.Dashboard.yearlyGymData,
    })
  )
  const [chartDate, setChartDate] = useState(Date.now())

  useEffect(() => {
    dispatch(getGym("", ""))
    dispatch(getAccount("", ""))
  }, [dispatch])

  useEffect(() => {
    dispatch(getYearlyChart(moment(chartDate).format("YYYY")))
  }, [dispatch, chartDate])

  const totalgyms = yearlyGymData?.total_gyms?.reduce((a, b) => a + b, 0)

  return (
    <div>
      <Row>
        <Col lg={7}>
          <MiniWidget />
          <Card>
            <CardBody>
              <CardTitle
                className="mb-1 d-flex "
                style={{ justifyContent: "space-between" }}
              >
                Yearly Analytics
                <div
                  className="clearfix w-fit-content"
                  style={{ width: "fit-content" }}
                >
                  <div className="float-end ">
                    <div
                      className="input-group input-group-sm "
                      style={{ width: "160px" }}
                    >
                      {" "}
                      <DatePicker
                        selected={chartDate}
                        onChange={date => setChartDate(date)}
                        dateFormat="yyyy"
                        showYearPicker
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </CardTitle>
              {yearGymLoading ? (
                <>
                  <Spinner type="grow" color="info" />
                </>
              ) : (
                <>
                  {totalgyms >= 1 ? (
                    <>
                      <p className="text-center m-0 p-0 mt-2 text-black">
                        {totalgyms} gyms added in{" "}
                        {moment(chartDate).format("YYYY")}
                      </p>
                      <BarChart chartData={yearlyGymData?.total_gyms} />
                    </>
                  ) : (
                    <p className="mt-4 text-info">
                      No gyms added in {moment(chartDate).format("YYYY")}
                    </p>
                  )}
                </>
              )}
            </CardBody>
          </Card>
          <RechargeTable />
        </Col>
        <Col lg={5}>
          <Card className="blog-stats-wid">
            <CardBody>
              <p className="mb-0 pb-0">User</p>

              <div className="d-flex flex-wrap mb-4">
                <div className="d-flex justify-content-end w-100">
                  <Link className="btn btn-success" to="/accounts/create">
                    <i className="bx bx-plus align-middle"></i> Add New User
                  </Link>
                </div>
              </div>
              {users?.results?.length > 0 && (
                <ThumbSlider isUser={true} data={users?.results} />
              )}
            </CardBody>
          </Card>
          <Card className="blog-stats-wid">
            <CardBody>
              <p className="mb-0 pb-0">Fitness Centers</p>
              <div className="d-flex flex-wrap mb-4">
                <div className="d-flex justify-content-end w-100">
                  <Link className="btn btn-success" to="/gym/create">
                    <i className="bx bx-plus align-middle"></i> Add New Gym
                  </Link>
                </div>
              </div>
              {gyms?.results?.length > 0 && (
                <ThumbSlider data={gyms?.results} />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default AdminDashboard
