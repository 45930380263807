import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import { Link } from "react-router-dom"

//actions

//redux
import { useSelector, useDispatch } from "react-redux"
import { logo, logo_sm } from "assets/images/logo"

const Sidebar = props => {
  const dispatch = useDispatch()

  const { sideBarSm } = useSelector(state => ({
    sideBarSm: state.Layout.sideBarSm,
  }))

  useEffect(() => {}, [dispatch])

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/dashboard" className="logo logo-dark">
            <span className="logo-sm">
              {sideBarSm ? (
                <img src={logo_sm} alt="lohalogo" height="18" />
              ) : (
                <img
                  src={logo_sm}
                  alt="lg-logo"
                  style={{
                    objectFit: "cover",
                    width: "50%",
                  }}
                />
              )}
            </span>
            <span className="logo-lg">
              {sideBarSm ? (
                <img src={logo_sm} alt="lohalogo" height="18" />
              ) : (
                <img
                  src={logo}
                  alt="lg-logo"
                  style={{
                    objectFit: "cover",
                    width: "50%",
                  }}
                />
              )}
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? (
            <SidebarContent props />
          ) : (
            <SidebarContent />
          )}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
