import MetaTag from "components/Common/Meta-tag"
import React, { useEffect } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { getSingleAccount } from "store/actions"
import { MdEmail } from "react-icons/md"
import { BsFillTelephoneFill } from "react-icons/bs"
import { CgProfile } from "react-icons/cg"
import { BiPhoneCall } from "react-icons/bi"
import GymListTable from "pages/FitnessCenters/Eelements/GymListTable"
import { IMAGE_URL } from "helpers/api_methods"

function UserView() {
  const dispatch = useDispatch()
  const params = useParams()

  const { loading, userDetails } = useSelector(state => ({
    loading: state.Fitcom.userLoad,
    userDetails: state.Fitcom.userDetails,
  }))

  useEffect(() => {
    dispatch(getSingleAccount(params.id))
  }, [params.id])

  return (
    <>
      <MetaTag title="User Account" />

      <div className="page-content">
        <Breadcrumbs
          title="Users"
          breadcrumbItem={userDetails?.first_name ?? ""}
        />
        <Container fluid>
          <Row>
            <Col>
              <Card style={{ height: "auto", minHeight: "0vh" }}>
                <CardBody>
                  <Row>
                    <Col xl="8">
                      <div className="bg-gray">
                        <h6 className="text-start text-muted">Gyms</h6>
                        <GymListTable
                          isMinimal={true}
                          userGymData={userDetails?.gym}
                        />
                      </div>
                    </Col>

                    <Col xl="4" className="col-12 col-md-4">
                      <div className="detail_card text-center">
                        <CardBody>
                          {userDetails?.profile_pic ? (
                            <div>
                              <img
                                src={`${IMAGE_URL}${userDetails?.profile_pic}`}
                                alt="profile"
                                height={80}
                                className="avatar-md rounded-circle"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <h5 className="mt-1 mb-0 pb-0">
                            {userDetails?.first_name}
                          </h5>
                          <p className="text-muted ">
                            {userDetails?.last_name}
                          </p>
                          <div className="text-start">
                            <p className="m-0 p-0 d-flex align-items-center ">
                              <MdEmail className="me-1" /> {userDetails?.email}
                            </p>
                            <p className="d-flex align-items-center ">
                              <BsFillTelephoneFill className="me-1" />{" "}
                              {userDetails?.phone}
                            </p>
                            <address className="text-muted font-size-12">
                              {userDetails?.address}
                            </address>
                          </div>
                          <div className="hstack gap-2 mt-3">
                            <button className="btn btn-soft-primary w-100">
                              <a href={`tel:${userDetails?.phone}`}>
                                <BiPhoneCall /> Contact
                              </a>
                            </button>
                            <button className="btn btn-soft-danger w-100">
                              <Link to={`/accounts/update/${userDetails?.id}`}>
                                <CgProfile className="me-1" /> Update
                              </Link>
                            </button>
                          </div>
                        </CardBody>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default UserView
