import React from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody } from "reactstrap"
import { deleteImg } from "assets/images/Defualt"
import Spinner from "./Spinner"

const DeleteModal = ({ onDeleteClick, onCloseClick, show, loading }) => {
  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={deleteModalStyle}
      className="delete-modal"
    >
      <ModalBody style={deleteModalBodyStyle} className="p-0">
        <div style={{ backgroundColor: "#fef1f0", borderRadius: "1.5rem" }}>
          <img
            src={deleteImg}
            className="px-5 py-4"
            style={{ height: "100%", width: "100%" }}
            alt="delete-image"
          />
        </div>
        <div className="text-center pt-4">
          <h6>Are you sure?</h6>
          <p className="font-size-12 text-muted">
            {"You won't be able to revert this!"}
          </p>
        </div>
        <div className="hstack gap-2 mt-3 px-4">
          <button
            className="btn btn-outline-dark w-100"
            style={{ borderRadius: 10 }}
            onClick={onCloseClick}
          >
            Cancel
          </button>
          <button
            onClick={onDeleteClick}
            style={{ borderRadius: 10 }}
            className="btn btn-danger w-100"
          >
            Delete&nbsp;
            {loading && <Spinner />}
          </button>
        </div>
      </ModalBody>
    </Modal>
  )
}

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.bool,
  loading: PropTypes.bool,
}

export default DeleteModal

const deleteModalStyle = {
  width: 300,
}
const deleteModalBodyStyle = {
  minHeight: 300,
}
