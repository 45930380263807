const {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
} = require("./actionTypes")

const initial_state = {
  error: "",
  loading: false,
  admin: [],
  students: [],
}

const Login = (state = initial_state, action) => {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
    case LOGOUT:
      return {
        ...state,
        loading: true,
      }

    case LOGIN_USER_FAIL:
    case LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        admin: action.payload,
      }

    case LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        admin: [],
      }

    default:
      return state
  }
}

export default Login
