import React from "react"
import { useSelector } from "react-redux"
import { Col, Card, CardBody, Row } from "reactstrap"

const MiniWidget = ({}) => {
  const { gyms, users } = useSelector(state => ({
    gyms: state.Fitcom.gyms,
    users: state.Fitcom.users,
  }))
  return (
    <React.Fragment>
      <Row>
        <Col lg={6}>
          <Card className="mini-stats-wid">
            <CardBody>
              <div className="d-flex flex-wrap">
                <div className="me-3">
                  <p className="text-muted mb-2">Total Users</p>
                  <h5 className="mb-0">{users?.count}</h5>
                </div>

                <div className="avatar-sm ms-auto">
                  <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                    <i className="bx bxs-book-bookmark"></i>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6}>
          <Card className="mini-stats-wid">
            <CardBody>
              <div className="d-flex flex-wrap">
                <div className="me-3">
                  <p className="text-muted mb-2">Total Gyms</p>
                  <h5 className="mb-0">{gyms?.count}</h5>
                </div>

                <div className="avatar-sm ms-auto">
                  <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                    <i className="bx bxs-book-bookmark"></i>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default MiniWidget
