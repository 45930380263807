import { useNotification } from "components/Common/Notification"

export function errorNotification(msg) {
  useNotification({
    type: "error",
    message: msg ? msg : "Something Went Wrong",
    title: "Try Again",
  })
}

export function doneNotification(msg) {
  useNotification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}
