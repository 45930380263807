import React from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

import "../../assets/scss/datatables.scss"
import GymListTable from "./Eelements/GymListTable"

const AllGymList = () => {
  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <GymListTable />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllGymList
