import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_CALENDER_SCHEDULE,
  GET_DASHBOARDDATA,
  GET_YEARLY_CHART,
} from "./actionTypes"
import {
  getDashboardDataSuccess,
  getDashboardDataFail,
  getCalenderDataSuccess,
  getCalenderDataFail,
  getYearlyChartSuccess,
  getYearlyChartFail,
} from "./actions"
import { get } from "helpers/api_methods"

const getDashboardDataAPi = () => {
  return get(`/dashboard/dashboard_cards/`)
}

function* fetchDashboardData() {
  try {
    const response = yield call(getDashboardDataAPi)
    yield put(getDashboardDataSuccess(response))
  } catch (error) {
    yield put(getDashboardDataFail(error))
  }
}

const getCalenderApi = () => {
  return get(`/booking-timeline-view`)
}
function* fetchCalenderData() {
  try {
    const response = yield call(getCalenderApi)
    yield put(getCalenderDataSuccess(response))
  } catch (error) {
    yield put(getCalenderDataFail(error))
  }
}

const getYearlyChartAPi = ({ date }) => {
  return get(
    `/gym/yearly-gym-graph?date=${
      date ? date : moment(Date.now()).format("YYYY")
    }`
  )
}
function* fetchYearlyChart({ payload }) {
  try {
    const response = yield call(getYearlyChartAPi, payload)
    yield put(getYearlyChartSuccess(response))
  } catch (error) {
    yield put(getYearlyChartFail(error))
  }
}

function* DashboardSaga() {
  yield takeEvery(GET_DASHBOARDDATA, fetchDashboardData)
  yield takeEvery(GET_YEARLY_CHART, fetchYearlyChart)
  yield takeEvery(GET_CALENDER_SCHEDULE, fetchCalenderData)
}

export default DashboardSaga
